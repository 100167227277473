<template>
  <div class='indexbox'>
    <nav-bar title=" " left-arrow bg-color="none" />
    <div class="bodybox">
      <img src="@/assets/img/logo.png" alt="">
      <div class="code">
        <qr-code :text="url" v-if="url" />
      </div>
      <!-- <p><text-span>邀请码</text-span>：{{ info.invite_code }}</p> -->
      <p class="url">{{ url }}</p>
      <van-button block round @click="copyData(url)" v-if="url">{{ $t('复制并保存') }}</van-button>

    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        url: '',
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
            this.url = `${window.location.origin}/#/?code=${this.info.invite_code}`
          },
          tip: () => { },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: url('../../../assets/img/bg@2.png') no-repeat;
    background-size: 100% 100%;
    .bodybox {
      width: 100%;
      min-height: 90vh;
      padding: 80px 30px 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      img {
        width: 120px;
        height: 120px;
        border-radius: 12px;
      }
      .code {
        padding: 10px;
        background: #fff;
        border-radius: 10px;
      }
      >p {
        font-size: 20px;
        color: #fff;
        margin: 20px 0;
        font-weight: 550;
      }
      .url {
        width: 80%;
        text-align: center;
      }
    }
  }
</style>
