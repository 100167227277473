<template>
  <div class='indexbox'>
    <head-box :address="info.account" ref="headRef" />
    <div class="bodybox">
      <van-swipe class="swipebox" :autoplay="3000" indicator-color="#E9DBFF">
        <van-swipe-item v-for="(item, index) in banner" :key="index">
          <img :src="item.image" alt="">
        </van-swipe-item>
      </van-swipe>
      <div class="navbox">
        <router-link to="pledge">
          <img src="@/assets/img/u.png" alt="">
          <text-span>算力质押</text-span>
        </router-link>
        <router-link to="about">
          <img src="@/assets/img/h.png" alt="">
          <text-span>公司简介</text-span>
        </router-link>
        <router-link to="site">
          <img src="@/assets/img/r.png" alt="">
          <text-span>全球矿场</text-span>
        </router-link>
        <router-link to="share">
          <img src="@/assets/img/shar.png" alt="">
          <text-span>邀请好友</text-span>
        </router-link>
      </div>
      <div class="newbox">
        <img src="@/assets/img/y.png" alt="" @click="router('news')" class="l">
        <van-swipe class="new" :autoplay="4000" vertical :show-indicators="false">
          <van-swipe-item v-for="val in notice" :key="val.id"
            @click="$router.push({ name: 'newdel', query: { id: val.id } })">
            <p class="one">{{ val.title }}</p>
          </van-swipe-item>
        </van-swipe>
        <img src="@/assets/img/j.png" alt="" class="r">
      </div>
      <div class="videobox">
        <video src="@/assets/xau.mp4" controls controlslist="nodownload" disablePictureInPicture></video>
      </div>
      <div class="tit">
        <div class="l">
          <img src="@/assets/img/dt.png" alt="">
          <text-span>市场动态</text-span>
        </div>
        <!-- <div class="r">
          <span>查看更多</span>
          <van-icon name="arrow" color="#fff" size="13" />
        </div> -->
      </div>
      <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id" @click="router(`newdel?id=${val.id}`)">
          <img v-lazy="val.image" :alt="val.title">
          <p class="n one">{{ val.title }}</p>
          <p class="time">{{ val.create_time }}</p>
        </div>
      </van-list>
    </div>

    <van-dialog v-model="show" :confirmButtonText="$t('授权登录')" @confirm="weblogin">
      <div class="cont">
        <div v-html="html"></div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
  import Web3 from 'web3'
  export default {
    data() {
      return {
        banner: [],
        notice: [],
        list: [],
        page: 1,
        loading: false,
        finished: false,
        info: {},
        html: '',
        show: false,

        code: '',
        address: '',
        token: '',
      }
    },
    created() {
      if (this.$route.query.code) {
        this.code = this.$route.query.code
      }
      if (this.session.get("token")) {
        this.token = this.session.get("token")
      }
      this.getdatas()
      this.getuser()
    },
    mounted() { },
    methods: {
      getdatas() {
        this.$get({
          url: "/app/index/getShengming",
          success: (res) => {
            this.html = res.data.shengming
          },
          tip: () => { },
        });
        this.$get({
          url: "/app/slider/sliderList",
          success: (res) => {
            this.banner = res.data;
          },
          tip: () => { },
        });
        this.$get({
          url: "/app/article/pageList?cate_id=1",
          success: (res) => {
            this.notice = res.data.data
          },
          tip: () => { },
        });
      },
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/app/article/pageList?cate_id=2",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
      getuser() {
        this.$nextTick(() => {
          if (!this.token) {
            if (this.html) {
              this.show = true
            } else {
              this.weblogin()
            }
          }
          if (this.token) {
            this.getdata();
          }
        });
      },
      weblogin() {
        this.$toast.loading({
          forbidClick: true,
        })
        var time;
        time = setInterval(() => {
          this.address = this.$store.state.wallet.account;
          if (this.$store.state.wallet.account != "") {
            clearInterval(time);
            this.code && console.warn('code', this.code);
            let message = this.getGuid()
            let web3 = new Web3(window.ethereum);
            web3.eth.personal.sign(message, this.address, async (err, res) => {
              if (res != null && res !== undefined && res !== '') {
                this.$post({
                  url: "/app/login/login",
                  noToken: true,
                  loading: true,
                  data: {
                    account: this.address,
                    msg: message,
                    sign: res,
                    invite_code: this.code,
                  },
                  success: (res) => {
                    console.warn("登录成功");
                    this.session.set("token", res.data.token)
                    this.$toast({
                      message: this.$t('登录成功'),
                      onClose: () => {
                        this.$refs.headRef.show = true
                        this.getdata();
                      }
                    })
                  },
                  tip: () => { },
                });
              } else {
                this.$toast.clear()
                this.$dialog.alert({
                  message: '签名失败',
                }).then(() => {
                  this.session.del("token")
                  window.location.reload();
                });
              }
            })
          }
        }, 1000);
      },
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },

    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: url('../../assets/img/bg@2x.png') no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    padding: 0 0 50px;
  }
  .bodybox {
    width: 100%;
    padding: 0 12px;
    .swipebox {
      width: 100%;
      height: 155px;
      border-radius: 10px;
      overflow: hidden;
      :deep(.van-swipe-item) {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .navbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0;
      >a {
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        img {
          width: 70px;
          height: 70px;
        }
        span {
          margin-top: -10px;
          font-size: 15px;
          font-weight: 550;
          color: #fff;
        }
      }
    }
    .newbox {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 16px;
      .l {
        width: 16px;
        height: 18px;
      }
      .new {
        flex: 1;
        height: 30px;
        padding: 0 12px;
        :deep(.van-swipe__track) {
          width: 100%;
          height: 30px !important;
        }
        :deep(.van-swipe-item) {
          width: 100%;
          height: 30px !important;
          p {
            width: 100%;
            line-height: 30px;
            font-size: 14px;
            font-weight: 550;
          }
        }
      }
      .r {
        width: 8px;
        height: 13px;
      }
    }
    .videobox {
      width: 100%;
      padding: 10px 0;
      video {
        width: 100%;
        height: 210px;
        object-fit: contain;
      }
      video::-internal-media-controls-overflow-button {
        display: none !important;
      }
    }
    .tit {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1px 0 10px;
      .l {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font-size: 18px;
          font-weight: 550;
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
      .r {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
          font-size: 13px;
        }
      }
    }
    .item {
      width: 100%;
      background: linear-gradient(90deg, #320273 0%, #070226 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 165px;
      }
      .n {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        font-weight: 550;
      }
      .time {
        width: 100%;
        padding: 0 10px 10px;
        font-size: 13px;
      }
    }
  }
  :deep(.van-dialog__content) {
    padding: 10px 0;
  }
  .cont {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px 20px;
  }
</style>