<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('公告列表')" />
    <div class="list">
      <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
        <div class="item" v-for="(val, index) in list" :key="index" @click="router(`newdel?id=${val.id}`)">
          <p>{{ val.title }}</p>
          <p>{{ val.create_time }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    created() { },
    methods: {
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/app/article/pageList?cate_id=1",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .list {
    width: 100%;
    padding: 10px 15px;
    .item {
      width: 100%;
      padding: 10px;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      font-size: 15px;
      p {
        &:nth-child(2) {
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
</style>