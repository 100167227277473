import Vue from 'vue'
import App from './App'
import router from './router/index'
import 'vant/lib/index.css';
import './assets/css/reset.css';
import http from './request/http'
Vue.use(http)

import store from './store';
import Api from './ethChain'
import web3Fun from './utils/web3'
import * as blockFun from './utils/chain/block'
Vue.use(Api)
Vue.use(web3Fun)
Vue.prototype.blockFun = blockFun
import { i18n } from './utils/lang'

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
