<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('钱包明细')" />
    <div class="num" v-if="type == 1">
      <div class="t">
        <span>USDT</span>
      </div>
      <p>{{ info.balance }}U</p>
    </div>
    <div class="num" v-if="type == 2">
      <div class="t">
        <span>XAU</span>
      </div>
      <p>{{ info.xau }}</p>
    </div>
    <div class="num" v-if="type == 3">
      <div class="t">
        <span>XAU</span><text-span>链上提取</text-span>
      </div>
      <p>{{ info.coin_usdt_value }}U</p>
    </div>
    <div class="num" v-if="type == 4">
      <div class="t">
        <span>OKT</span>
      </div>
      <p>{{ info.okt }}</p>
    </div>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
      <div class="item" v-for="val in list" :key="val.id">
        <div class="l">
          <p>{{ val.remark }}</p>
          <p>{{ val.create_time }}</p>
        </div>
        <div class="n" :class="{ 'a': Number(val.after) < Number(val.before) }"> {{ val.amount }}</div>
      </div>
    </van-list>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        type: this.urlQuery().type,//1 USDT钱包 2 XAU钱包 3 XAU链上提取 4 OKT明细
        list: [],
        page: 1,
        loading: false,
        finished: false,
        info: {},
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      onLoad() {
        let page = this.page++
        this.$get({
          url: "/app/balanceLog/pageList",
          data: {
            page,
            balance_type: this.type
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },

    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 15px 30px;
    .num {
      width: 100%;
      padding: 20px 15px 15px;
      background: url('../../../assets/img/yy.png') no-repeat;
      background-size: 100% 100%;
      .t {
        width: 100%;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      >p {
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 550;
      }
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      margin-top: 15px;
      padding: 10px 15px;
      .l {
        width: 50%;
        p {
          width: 100%;
          font-size: 16px;
          font-weight: 550;
          &:nth-child(2) {
            font-size: 13px;
            margin-top: 3px;
            font-weight: 500;
          }
        }
      }
      .n {
        width: 38%;
        text-align: right;
        color: #E760FF;
        font-size: 18px;
        font-weight: 550;
      }
      .a {
        color: #fff;
      }
    }
  }
</style>