import { render, staticRenderFns } from "./turnlist.vue?vue&type=template&id=4edb6017&scoped=true"
import script from "./turnlist.vue?vue&type=script&lang=js"
export * from "./turnlist.vue?vue&type=script&lang=js"
import style0 from "./turnlist.vue?vue&type=style&index=0&id=4edb6017&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4edb6017",
  null
  
)

export default component.exports