<template>
  <div class='indexbox'>
    <nav-bar :title="$t('订单详情')" left-arrow />

    <div class="bodybox ">
      <div class="headerbox box">
        <text-p v-if="info.status == 2">待发货</text-p>
        <text-p v-if="info.status == 2">商家正在打包中，请耐心等待</text-p>
        <text-p v-if="info.status == 3">待收货</text-p>
        <text-p v-if="info.status == 3">您的快递正在飞奔向您，请耐心等待</text-p>
        <text-p v-if="info.status == 4">已完成</text-p>
        <text-p v-if="info.status == 4">订单已完成，期待下次下单</text-p>
      </div>
      <div class="addresbox box">
        <img src="@/assets/img/t.png" alt="">
        <div class="info">
          <div class="name">
            <span>{{ info.consignee_name }}</span>
            <span> {{ info.consignee_mobile }}</span>
          </div>
          <p class="addres">{{ info.address }}-{{ info.detail }}</p>
        </div>
      </div>
      <div class="goodsbox box">
        <img v-lazy="info.image" alt="">
        <div class="right">
          <p class="name two">{{ info.goods_name }}</p>
          <p class="namea ">{{ info.small_text }}</p>
          <p class="price" v-if="info.type < 4">{{ info.goods_price }}</p>
        </div>
      </div>
      <div class="listbox box">
        <div>
          <text-p>订单编号</text-p>
          <p>{{ info.order_no }}</p>
        </div>
        <div>
          <text-p>下单时间</text-p>
          <p>{{ info.create_time }}</p>
        </div>
        <!-- <div v-if="info.remark">
          <p>备注</p>
          <p>{{ info.remark }}</p>
        </div> -->
        <div v-if="info.pay_time">
          <text-p>付款时间</text-p>
          <p>{{ info.pay_time }}</p>
        </div>
        <div v-if="info.status > 2">
          <text-p>发货时间</text-p>
          <p>{{ info.delivery_time }}</p>
        </div>
        <div v-if="info.express_name && info.status > 2">
          <text-p>快递公司</text-p>
          <p>{{ info.express_name }}</p>
        </div>
        <div @click="copyData(info.express_no)" v-if="info.express_no && info.status > 2">
          <text-p>快递单号</text-p>
          <p>{{ info.express_no }}</p>
        </div>
        <div v-if="info.finish_time && info.status == 4">
          <text-p>完成时间</text-p>
          <p>{{ info.finish_time }}</p>
        </div>
      </div>
      <div class="listbox nums box">
        <div>
          <text-p>数量</text-p>
          <p>x{{ info.num }}</p>
        </div>
        <div class="num">
          <text-p>商品金额</text-p>
          <p><span> {{ info.pay_money }}</span></p>
        </div>
      </div>
    </div>

    <van-goods-action v-if="info.status == 3">
      <div style="width:60%"></div>
      <van-goods-action-button :loading='loading' type="danger" :text="$t('确认收货')" @click="complete" />
    </van-goods-action>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.$route.query.id,
        info: {},
        loading: false,
      };
    },
    methods: {
      getdata() {
        this.$post({
          loading: true,
          url: '/app/mallOrders/info',
          data: {
            order_id: this.id,
          },
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        })
      },
      complete() {
        this.$toast.loading({
          message: this.$t('收货中'),
          forbidClick: true,
        })
        this.loading = true
        this.$post({
          url: '/app/mallOrders/confirm',
          data: {
            order_id: this.id
          },
          success: (res) => {
            this.loading = false
            this.$toast({
              message: this.$t('收货成功'),
              onClose: () => {
                this.getdata()
              }
            })
          },
          tip: () => {
            setTimeout(() => {
              this.loading = false
            }, 1500);
          },
        })
      },
    },
    created() {
      window.scrollTo(0, 0)
      this.getdata()
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    padding: 0 0 100px;
    .bodybox {
      width: 100%;
      padding: 0 15px;
      .box {
        background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
        border-radius: 8px;
        border: 1px solid #7F4AF4;
      }
      .headerbox {
        width: 100%;
        padding: 20px 15px;
        margin-bottom: 20px;
        p {
          color: #FFFFFF;
          font-size: 13px;
          &:nth-child(1) {
            font-size: 20px;
            font-weight: 550;
            margin-bottom: 5px;
          }
        }
      }
      .addresbox {
        width: 100%;
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        img {
          width: 30px;
          height: 30px;
        }
        .info {
          width: 88%;
          .name {
            width: 100%;
            font-size: 14px;
            font-weight: 550;
          }
          .addres {
            width: 100%;
            font-size: 13px;
          }
        }
      }
      .goodsbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        margin-bottom: 15px;
        >img {
          width: 85px;
          height: 85px;
          border-radius: 8px;
        }
        .right {
          width: 70%;
          min-height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .name {
            width: 100%;
            font-size: 14px;
            font-weight: 550;
            line-height: 1.5;
          }
          .price {
            width: 100%;
            color: #7F4AF4;
            font-size: 16px;
            font-weight: 550;
          }
        }
      }
      .listbox {
        width: 100%;
        padding: 10px 15px;
        margin-bottom: 15px;
        >div {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 0;
          p {
            width: 25%;
            font-size: 14px;
            &:nth-child(2) {
              width: 74%;
            }
          }
        }
      }
      .nums {
        >div {
          p {
            &:nth-child(2) {
              text-align: right;
            }
          }
        }
        .num {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            color: @theme;
            font-size: 16px;
            font-weight: 550;
            padding-left: 10px;
          }
        }
      }
    }
  }
</style>
