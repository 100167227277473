<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('算力质押')" />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="body">
        <p class="t">1XAU ≈ {{ price.xau_price || '-' }}USDT</p>
        <!-- <p class="t">1XAU ≈ {{ price.xau_okt_rate }}OKT</p> -->
        <p class="t">1OKT ≈ {{ price.okt_price || '-' }}USDT</p>
        <div class="box">
          <text-p class="tt">支付方式</text-p>
          <div class="b">
            <div :class="{ active: pay_type == 1 }" @click="pay_type = 1">
              <img src="@/assets/img/USDT@2x.png" alt="">
              <span>USDT</span>
            </div>
            <div :class="{ active: pay_type == 2 }" @click="pay_type = 2">
              <img src="@/assets/img/okt.png" alt="">
              <span>OKT</span>
            </div>
          </div>
          <van-field v-model="num" :label="$t('质押数量')" clearable input-align="right" :placeholder="$t('请输入质押数量')"
            :border="false" />
          <van-field :value="num || 0" :label="$t('需支付')" input-align="right" readonly :border="false">
            <template #button>
              <p style="color:#fff" v-if="pay_type == 1">U</p>
              <p style="color:#fff" v-if="pay_type == 2">OKT</p>
            </template>
          </van-field>
          <van-field :value="info.balance" v-if="pay_type == 1" :label="$t('USDT余额')" input-align="right" readonly
            :border="false">
            <template #button>
              <p style="color:#fff">U</p>
            </template>
          </van-field>

          <van-field :value="info.okt" v-if="pay_type == 2" :label="$t('OKT余额')" input-align="right" readonly
            :border="false">
            <template #button>
              <p style="color:#fff">OKT</p>
            </template>
          </van-field>
          <van-button round block :loading="bloading" @click="submit">{{ $t('确定') }}</van-button>
        </div>
        <div class="tit">
          <span class="heng"></span><text-span>质押记录</text-span>
        </div>
        <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
          <div class="item" v-for="val in list" :key="val.id">
            <div v-if="val.type == 1">
              <text-p>体验矿机</text-p>
            </div>
            <div>
              <text-p>质押数量</text-p>：{{ val.amount }}
              <template v-if="val.pay_type == 1">USDT</template>
              <template v-if="val.pay_type == 2">OKT</template>
            </div>
            <div>
              <text-p>支付金额</text-p>：
              <span>{{ val.amount }}
                <template v-if="val.pay_type == 1">USDT</template>
                <template v-if="val.pay_type == 2">OKT</template>
              </span>
            </div>
            <div>
              <text-p>XAU总算力</text-p>：{{ val.total_xau }}
            </div>
            <div>
              <text-p>XAU已释放算力</text-p>：{{ val.income }}
            </div>
            <div class="time">
              <text-p>质押时间</text-p>：{{ val.create_time }}
            </div>
            <text-p class="status" v-if="val.status == 0">释放中</text-p>
            <text-p class="status" v-if="val.status == 1">已结束</text-p>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        info: {},
        price: {},

        num: '',
        pay_type: 1,//1USDT  2OKT
        bloading: false,

        list: [],
        page: 1,
        loading: false,
        finished: false,
        isLoading: false,
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getinfo()
      this.getdata()
    },
    mounted() { },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/pledge/getXauPrice",
          success: (res) => {
            this.price = res.data
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          },
          tip: () => {
            this.isLoading = false;
          },
        });
      },
      getinfo() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      onRefresh() {
        this.price = {}
        this.getdata()
      },
      submit() {
        if (!this.num) return this.$toast('请输入质押数量')
        this.bloading = true
        this.$post({
          loading: true,
          url: "/app/pledgeOrders/pledgeDo",
          data: { amount: this.num, pay_type: this.pay_type },
          success: (res) => {
            this.bloading = false
            this.num = ''
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.getdata()
                this.page = 1
                this.finished = false
                this.onLoad()
              }
            })
          },
          tip: () => {
            this.bloading = false
          },
        });
      },
      onLoad() {
        let page = this.page++
        this.$post({
          url: '/app/pledgeOrders/pageList',
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    padding: 0 0 40px;
    .body {
      width: 100%;
      padding: 10px 12px;
      .t {
        width: 100%;
        text-align: center;
        font-weight: 550;
      }
      .box {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin: 15px 0;
        padding: 15px;
        .tt {
          font-size: 17px;
          font-weight: 550;
        }
        .b {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
          border-bottom: 1px solid hsla(0, 0%, 100%, .2);
          margin-bottom: 10px;
          >div {
            width: 48%;
            height: 45px;
            border-radius: 8px;
            border: 1px solid #EDE5FF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 5px
            }
          }
          .active {
            border: 1px solid #A874FF;
            color: #A874FF;
          }
        }
        :deep(.van-field) {
          background: none;
          padding: 8px 0 5px;
          .van-cell__title,
          .van-field__control {
            color: #Fff;
          }
        }
        :deep(.van-button) {
          margin: 50px 0 10px;
        }
      }
      .tit {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .heng {
          width: 3px;
          height: 15px;
          background: linear-gradient(135deg, #73A3FF 0%, #8C26FF 100%);
          border-radius: 2px;
          margin-right: 8px;
        }
        span {
          font-size: 17px;
        }
      }
      .item {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin: 15px 0;
        padding: 15px 30px 15px 15px;
        position: relative;
        overflow: hidden;
        >div {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 15px;
          line-height: 1.7;
          font-weight: 550;
          span {
            color: #BB92FF;
          }
        }
        .time {
          color: #fcfcfc;
        }
        .status {
          position: absolute;
          top: 0;
          right: 0;
          width: 115px;
          text-align: center;
          padding: 22px 10px 5px;
          background: linear-gradient(90deg, #696AF8 0%, #955EE9 100%);
          transform: rotate(45deg) translate(25px, -38px);
          font-size: 13px;
        }
      }
    }
  }
</style>