<template>
  <div class='indexbox'>
    <nav-bar :title="$t('兑换专区')" />
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
      <div class="listbox">
        <div class="item" @click="router(`details?id=${val.id}`)" v-for="val in list" :key="val.id">
          <img v-lazy="val.image" alt="">
          <div class="msgbox">
            <p class="name one">{{ val.name }}</p>
            <p class=" price one">{{ val.price }} XAU</p>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    computed: {},
    watch: {},
    methods: {
      onLoad() {
        let page = this.page++
        this.$post({
          url: '/app/mallGoods/pageList',
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      }
    },
    created() { },
    mounted() { },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 40px;
    .listbox {
      width: 100%;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      .item {
        width: 48%;
        background: linear-gradient(90deg, #320273 0%, #070226 100%);
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        overflow: hidden;
        margin: 0 0 15px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        >img {
          width: 100%;
          height: 165px;
          object-fit: contain;
        }
        .msgbox {
          width: 100%;
          padding: 8px 10px;
          .name {
            width: 100%;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 550;
          }
          .price {
            width: 100%;
            color: #A874FF;
            font-size: 15px;
            font-weight: 550;
          }
        }
      }
    }
  }
</style>