import store from '../store'
import Web3 from 'web3'

const web3Fun = {
  // 初始化并获取账号
  init() {
    return new Promise((resolve, reject) => {
      if (typeof window.okxwallet !== 'undefined') {
        console.warn('okx wallet');
        store.commit("SET_CHAIN", 1)
        okxwallet.request({ method: 'eth_requestAccounts' })
          //如果用户拒绝了登录请求
          .catch(reason => {
            store.commit("SET_WALLET_CONNECT", false)
            if (reason === 'User rejected provider access') {
              // 用户不想登录，你看该怎么办？
              reject(new Error('用户不想登录，你看该怎么办'));
            } else {
              // 本不该执行到这里，但是真到这里了，说明发生了意外
              reject(new Error('说明发生了意外'));
            }
          })
          //如果用户同意了登录请求，你就可以拿到用户的账号
          .then(accounts => {
            //一旦获取了用户账号，你就可以签名交易
            if (process.env.VUE_APP_OPEN_SIGN == 'true') {
              let web3 = new Web3(window.ethereum);
              web3.eth.personal.sign(web3.utils.fromUtf8(process.env.VUE_APP_SIGN_TIP), accounts[0], (err, res) => {
                if (res != null && res !== undefined && res !== '') {
                  this.initEvent(1)
                  store.commit("SET_WALLET_CONNECT", true)
                  store.commit("SET_WALLET_ACCOUNT", accounts[0])
                  store.commit("SET_WALLET_SIGN", res)
                  resolve(accounts)
                } else {
                  reject(new Error('签名失败'))
                }
              })
            } else {
              console.log("%c 签名授权 %c 关闭 ",
                'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px; margin:5px 0; color: #fff',
                'background:#ec4119 ; padding: 1px; border-radius: 0 3px 3px 0; margin:5px 0; color: #fff',
              )
              this.initEvent(1)
              store.commit("SET_WALLET_CONNECT", true)
              store.commit("SET_WALLET_ACCOUNT", accounts[0])
              resolve(accounts)
            }
          })
      } else {
        // if (typeof window.ethereum != 'undefined')
        //检测当前浏览器是否以太坊兼容，并进行相应的处理
        console.warn('ethereum');
        store.commit("SET_CHAIN", 66)
        window.ethereum.request({ method: 'eth_requestAccounts' })
          //如果用户拒绝了登录请求
          .catch(reason => {
            store.commit("SET_WALLET_CONNECT", false)
            if (reason === 'User rejected provider access') {
              // 用户不想登录，你看该怎么办？
              reject(new Error('用户不想登录，你看该怎么办'));
            } else {
              // 本不该执行到这里，但是真到这里了，说明发生了意外
              reject(new Error('说明发生了意外'));
            }
          })
          //如果用户同意了登录请求，你就可以拿到用户的账号
          .then(accounts => {
            //一旦获取了用户账号，你就可以签名交易
            if (process.env.VUE_APP_OPEN_SIGN == 'true') {
              let web3 = new Web3(window.ethereum);
              web3.eth.personal.sign(web3.utils.fromUtf8(process.env.VUE_APP_SIGN_TIP), accounts[0], (err, res) => {
                if (res != null && res !== undefined && res !== '') {
                  this.initEvent(66)
                  store.commit("SET_WALLET_CONNECT", true)
                  store.commit("SET_WALLET_ACCOUNT", accounts[0])
                  store.commit("SET_WALLET_SIGN", res)
                  resolve(accounts)
                } else {
                  reject(new Error('签名失败'))
                }
              })
            } else {
              console.log("%c 签名授权 %c 关闭 ",
                'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px; margin:5px 0; color: #fff',
                'background:#ec4119 ; padding: 1px; border-radius: 0 3px 3px 0; margin:5px 0; color: #fff',
              )
              this.initEvent(66)
              store.commit("SET_WALLET_CONNECT", true)
              store.commit("SET_WALLET_ACCOUNT", accounts[0])
              resolve(accounts)
            }
          })
      }
    })
  },
  // 监控改变事件
  async initEvent(isChain) {
    if (isChain == 1) {
      // 帐户更改事件
      okxwallet.on('accountsChanged', (accounts) => {
        console.log(accounts)
        store.commit("SET_WALLET_ACCOUNT", accounts[0])
        window.localStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.sessionStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.location.reload();
      })
      // 链改变事件
      okxwallet.on('chainChanged', (chainId) => {
        console.log(chainId)
        store.commit("SET_WALLET_CHAIN_ID", chainId)
        window.localStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.sessionStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.location.reload();
      })
      okxwallet.on('message', (message) => {
        // 消息类型：ProviderMessage
        // ProviderMessage {
        //   type: string;
        //   data: unknown;
        // }
        console.log(message)
      })
    } else {
      // 帐户更改事件
      window.ethereum.on('accountsChanged', (accounts) => {
        // 如果返回数组中的第一个帐户不是您期望的帐户，您应该通知用户！将来，accounts 数组可能包含多个帐户。但是，数组中的第一个帐户将继续被视为用户的“选定”帐户。
        console.log(accounts)
        store.commit("SET_WALLET_ACCOUNT", accounts[0])
        window.localStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.sessionStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.location.reload();
      })
      // 链改变事件
      window.ethereum.on('chainChanged', (chainId) => {
        console.log(chainId)
        store.commit("SET_WALLET_CHAIN_ID", chainId)
        window.localStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.sessionStorage.removeItem(`${process.env.VUE_APP_NAME}token`);
        window.location.reload();
      })
      window.ethereum.on('message', (message) => {
        // 消息类型：ProviderMessage
        // ProviderMessage {
        //   type: string;
        //   data: unknown;
        // }
        console.log(message)
      })
    }
  },
  getWeb3() {
    // 初始化web3
    if (window.okxwallet) {
      let web3 = new Web3(window['okxwallet']);
      store.commit('SET_WALLET_WEB3', web3);
      return web3;
    } else if (window.ethereum) {
      let web3 = new Web3(window['ethereum'] || window.web3.currentProvider);
      store.commit('SET_WALLET_WEB3', web3);
      return web3;
    }
    return null;
  },
  async getContract(web3, abis) {
    if (web3 && abis) {
      Object.keys(abis).forEach(key => {
        let contract = new web3.eth.Contract(abis[key].abi, abis[key].address, {
          from: store.state.wallet.account,
          value: 0,
          gas: 600000,
          gaslimit: 10
        })
        store.commit('SET_WALLET_CONTRACT', { key: key, contract: contract })
      })
    }
  },
}

const install = Vue => {
  if (install.installedToast) {
    return
  }
  install.installedToast = true
  Object.defineProperties(Vue.prototype, {
    $web3Fun: {
      get() {
        return web3Fun
      }
    }
  })
}
export default install
