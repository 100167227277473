<template>
  <div class='indexbox'>
    <div class="info">
      <img src="@/assets/img/logo.png" alt="">
      <div class="r">
        <div class="n" @click="copyData(info.account)">
          <p>{{ addrssfun(info.account) }}</p>
          <img src="@/assets/img/i.png" alt="">
        </div>
        <div class="b">
          <div>
            <!-- <p>XAU</p>：<span>{{ info.xau }}</span> -->
            <p>ID</p>：<span>{{ info.show_id }}</span>
          </div>
          <span class="heng"></span>
          <div>
            <text-p>级别</text-p>：<span>{{ info.level_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <text-p class="t">我的订单</text-p>
      <div class="nav">
        <div @click="routerlist(1)">
          <img src="@/assets/img/d.png" alt="">
          <text-span>待发货</text-span>
        </div>
        <div @click="routerlist(2)">
          <img src="@/assets/img/e.png" alt="">
          <text-span>待收货</text-span>
        </div>
        <div @click="routerlist(3)">
          <img src="@/assets/img/f.png" alt="">
          <text-span>已完成</text-span>
        </div>
        <div @click="routerlist(0)">
          <img src="@/assets/img/g.png" alt="">
          <text-span>全部订单</text-span>
        </div>
      </div>
    </div>
    <div class="box navs">
      <router-link to="team" class="i">
        <img src="@/assets/img/x.png" alt="">
        <text-span>市场管理</text-span>
      </router-link>
      <router-link to="addresslist" class="i">
        <img src="@/assets/img/t.png" alt="">
        <text-span>收货地址</text-span>
      </router-link>
      <router-link to="turn" class="i">
        <img src="@/assets/img/ty.png" alt="">
        <text-span>钱包转账</text-span>
      </router-link>
      <router-link to="share" class="i">
        <img src="@/assets/img/z.png" alt="">
        <text-span>邀请好友</text-span>
      </router-link>
      <router-link to="exchange" class="i">
        <img src="@/assets/img/l.png" alt="">
        <text-span>兑换</text-span>
      </router-link>

      <router-link to="" class="i">
        <img src="@/assets/img/la.png" alt="">
        <text-span>链游</text-span>
      </router-link>
      <router-link to="" class="i">
        <img src="@/assets/img/nft@2x.png" alt="">
        <span>NFT</span>
      </router-link>
      <router-link to="" class="i">
        <img src="@/assets/img/m.png" alt="">
        <text-span>加密社交</text-span>
      </router-link>
      <router-link to="" class="i">
        <img src="@/assets/img/s.png" alt="">
        <span>AI</span>
      </router-link>

      <router-link to="mycode" class="i">
        <img src="@/assets/img/k.png" alt="">
        <text-span>我的节点</text-span>
      </router-link>
      <router-link to="" class="i">
        <img src="@/assets/img/n.png" alt="">
        <text-span>交易中心</text-span>
      </router-link>
      <a href="https://kf.manthon.shop/index/index/home?business_id=2&groupid=0&special=2&theme=0d0625" class="i">
        <img src="@/assets/img/kk.png" alt="">
        <text-span>客服</text-span>
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {}
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      addrssfun(data) {
        if (data) {
          return data.substr(0, 5) + '...' + data.substr(data.length - 5)
        }
        return ''
      },
      routerlist(index) {
        this.session.set('orderlist_index', index)
        this.$router.push('orderlist')
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 15px 12px 30px;
    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >img {
        width: 60px;
        height: 60px;
      }
      .r {
        width: 80%;
        .n {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 18px;
          color: #EDE5FF;
          font-weight: 550;
          img {
            width: 16px;
            height: 16px;
            margin-left: 10px;
          }
        }
        .b {
          width: 100%;
          margin-top: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >div {
            min-width: 25%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #EDE5FF;
            font-size: 16px;
            >span {
              color: #fff;
            }
          }
          .heng {
            width: 1px;
            height: 12px;
            background: #FFFFFF;
            margin: 0 15px;
          }
        }
      }
    }
    .box {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      margin-top: 20px;
      .t {
        width: 100%;
        font-size: 17px;
        font-weight: 550;
        padding: 20px 17px;
      }
      .nav {
        width: 100%;
        padding: 0 4px 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        >a,
        div {
          width: 25%;
          padding: 0 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 26px;
            height: 22px;
          }
          span {
            margin-top: 10px;
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }
    .navs {
      width: 100%;
      padding: 20px 0 2px;
      display: flex;
      flex-wrap: wrap;
      .i {
        width: 25%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 18px;
        img {
          width: 31px;
          height: 30px;
        }
        span {
          margin-top: 10px;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
</style>