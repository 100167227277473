import { Toast, Lazyload, Dialog, ImagePreview } from 'vant'
import VueClipboard from 'vue-clipboard2'
import dayjs from 'dayjs'
import router from '../router/index';
// 国际化
import { i18n, vantLocales } from '../utils/lang'
export default {
  install(Vue, option) {
    vantLocales(i18n.locale)

    Vue.use(Lazyload, {
      lazyComponent: true,
    });
    Vue.use(VueClipboard)
    // toast 提示
    Vue.prototype.toast = function (data) {
      Toast(data)
    }
    // 图片预览
    Vue.prototype.lookImg = function (images, index = 0,) {
      ImagePreview({
        images: [images],
        startPosition: index,
        closeable: true,
      });
    }
    // 拨打电话
    Vue.prototype.callTel = function (tell) {
      window.location.href = `tel://${tell}`
    }
    // 保留小数 num=>数字  n=>保留几位小数 is=>是否四舍五入
    Vue.prototype.toFixed = function (num, n, is = true) {
      if (is) return parseFloat(num.toFixed(n))
      if (!is) return Number(num).toFixed(n + 1).slice(0, -1);
    }
    //复制文本方法
    Vue.prototype.copyData = function (data) {
      Vue.prototype.$copyText(data.toString()).then(function (e) {
        Toast.success({
          message: i18n.t('复制成功'),
        });
      }, function (e) {
        Toast.fail({
          message: i18n.t('失败'),
        });
      })
      // navigator.clipboard.writeText(data);
    }
    //日期 dayjs
    Vue.prototype.$dayjs = dayjs
    //路由跳转
    Vue.prototype.router = function (name, data) {
      window.scrollTo(0, 0);
      if (typeof data == 'object') {
        router.push({ path: name, query: data });
      } else if (typeof data == 'number' || typeof data == 'string') {
        router.push({ path: name, query: { data } });
      } else {
        router.push(name);
      }
    }
    //获取URL参数
    Vue.prototype.urlQuery = function (name) {
      if (name) {
        return router.app._route.query[name];
      } else {
        return router.app._route.query;
      }
    }

    Vue.prototype.getParameters = function () {
      let url = JSON.parse('{"' + decodeURI(window.location.split("?")[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      return JSON.stringify(url);
    }

    // 获取URL参数 自动映射data里面
    Vue.prototype.setUrlData = function () {
      let obj = this.$route.query
      Object.keys(obj).map((item) => (this[item] = obj[item]))
    }
    //关闭页面跳转路由
    Vue.prototype.goReplace = function (path) {
      router.replace({ path });
    }
    //返回上一页
    Vue.prototype.goBack = function () {
      router.go(-1);
    }
    // 退出登录
    Vue.prototype.loginOut = function () {
      Dialog.confirm({
        title: "提示",
        message: "确认要退出登录吗?",
        confirmButtonColor: "#333",
      }).then(() => {
        localStorage.clear()
        sessionStorage.clear()
        router.replace('login')
      }).catch(() => {
        // on cancel
      });
    }
    Vue.prototype.isvideoFun = (data) => {
      let index = data.indexOf(".");
      let type = data.substring(index);
      if (/(.*)\.(mp4|rmvb|avi|ts)$/.test(type)) {
        return true;
      }
      return false
    }
    // 地址过滤
    Vue.prototype.addressFun = function (account) {
      if (!account) return ''
      if (account.length > 8) {
        return account.substr(0, 4) + '...' + account.substr(account.length - 5)
      } else {
        return account
      }
    }
    /**
     * 获取uuid
     */
    Vue.prototype.getGuid = () => {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }
    //操作 localStorage
    Vue.prototype.local = {
      set(key, value) {
        localStorage.setItem(`${process.env.VUE_APP_NAME}${key}`, value);
      },
      get(key) {
        return localStorage.getItem(`${process.env.VUE_APP_NAME}${key}`);
      },
      del(key) {
        localStorage.removeItem(`${process.env.VUE_APP_NAME}${key}`);
      }
    }
    //操作 sessionStorage
    Vue.prototype.session = {
      set(key, value) {
        sessionStorage.setItem(`${process.env.VUE_APP_NAME}${key}`, value);
      },
      get(key) {
        return sessionStorage.getItem(`${process.env.VUE_APP_NAME}${key}`);
      },
      del(key) {
        sessionStorage.removeItem(`${process.env.VUE_APP_NAME}${key}`);
      }
    }
    // 倒计时 time 结束日期(YYYY-MM-DD HH:mm:ss)
    Vue.prototype.timeDown = function (time) {
      let date = new Date()
      let newtime = date.getTime()
      let endtime = new Date(time.replace(/-/g, "/")).getTime()
      return Number(endtime - newtime)
    }
    // 国际化 获取语言
    Vue.prototype.getLang = function () {
      return i18n.locale
    }
    // 国际化 切换语言
    Vue.prototype.changeLang = function (lang, reload = false) {
      i18n.locale = lang
      vantLocales(lang)
      reload && window.location.reload()
    }
    // 浏览器页面跳转
    Vue.prototype.href = function (url) {
      window.location.href = url
    }
    // h5支付 后端返回form格式数据
    Vue.prototype.h5pay = function (data) {
      const div = document.createElement('paydata');
      div.innerHTML = data;
      document.body.appendChild(div);
      document.forms[0].setAttribute('target', '_self');
      document.forms[0].submit();
      div.remove();
    }
    // 是不是pc端
    Vue.prototype.isPc = function () {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    }
    // 安卓还是ios true=>安卓  false=>ios
    Vue.prototype.an_or_ios = function () {
      if (navigator.userAgent.match(/(Android)/i)) {
        return true
      } else if (navigator.userAgent.match(/(iPhone|iPad|iPod|ios)/i)) {
        return false
      }
    }

    /**
      *  以下是调用安卓 ios的方法
      */
    // 下载(保存)单张图片(url完整链接格式)
    Vue.prototype.downloadImg = function (url) {
      if (!window.android || !window.webkit) return this.toast('请在APP中打开尝试调用原生方法')
      if (this.an_or_ios()) {
        window.android.callAndroidDownloadImg(url);
      } else if (!this.an_or_ios()) {
        window.webkit.messageHandlers.callIOSDownloadImg.postMessage(url)
      }
    }
    // 下载(保存)单个图片(Base64格式)
    Vue.prototype.downloadBase64Img = function (img) {
      if (!window.android || !window.webkit) return this.toast('请在APP中打开尝试调用原生方法')
      if (this.an_or_ios()) {
        window.android.callAndroidDownloadBase64Img(img);
      } else if (!this.an_or_ios()) {
        window.webkit.messageHandlers.saveIOSBase64Image.postMessage(img)
      }
    }
    // 分享单图图片(url完整链接格式)
    Vue.prototype.shareImg = function (url) {
      if (!window.android || !window.webkit) return this.toast('请在APP中打开尝试调用原生方法')
      if (this.an_or_ios()) {
        window.android.sharingForAndroid(url);
      } else if (!this.an_or_ios()) {
        window.webkit.sharingForAppleOs.postMessage.postMessage(url)
      }
    }
    // 分享多图给微信好友(url完整链接格式)
    Vue.prototype.shareImgs = function (urls) {
      if (!window.android || !window.webkit) return this.toast('请在APP中打开尝试调用原生方法')
      let imgs = JSON.stringify(urls);
      if (this.an_or_ios()) {
        window.android.sharingForAndroid(imgs);
      } else if (!this.an_or_ios()) {
        window.webkit.sharingForAppleOs.postMessage.postMessage(imgs)
      }
    }
    // 使用 SDK 分享链接
    Vue.prototype.shareSDK = function ({ title, link, image, content }) {
      if (!window.android || !window.webkit) return this.toast('请在APP中打开尝试调用原生方法')
      let data = JSON.stringify({ title, link, image, content })
      if (this.an_or_ios()) {
        window.android.sharingForAndroidBySDK(data);
      } else if (!this.an_or_ios()) {
        window.webkit.sharingWeixinForAppleOs.postMessage.postMessage(data)
      }
    }
  }
}