<template>
  <span>
    {{ $t($slots.default[0].text ? $slots.default[0].text : '') }}
  </span>
</template>
<script>
export default {
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() { },
  mounted() { },
}
</script>
