import { render, staticRenderFns } from "./rechargelist.vue?vue&type=template&id=38822d58&scoped=true"
import script from "./rechargelist.vue?vue&type=script&lang=js"
export * from "./rechargelist.vue?vue&type=script&lang=js"
import style0 from "./rechargelist.vue?vue&type=style&index=0&id=38822d58&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38822d58",
  null
  
)

export default component.exports