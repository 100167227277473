module.exports = {
  "中文": "중국어",
  "英语": "영어",
  "韩语": "한국어",
  "成功": "성공",
  "失败": "실패",
  "确认": "확인",
  "取消": "취소",
  "确定": "확인",
  "余额不足": "잔액 부족",
  "没有更多了": "더 이상 없어요.",
  "签名失败": "서명 실패",
  "详情": "상세 정보",
  "记录": "기록",
  "复制成功": "복사 성공 ",
  "首页": "첫 페이지",
  "算力质押": "계산력 질권",
  "兑换专区": "환전 코너",
  "全球矿场": "전 세계 광산",
  "邀请好友": "친구 초대",
  "市场动态": "시장 동향",
  "商品详情": "상품 상세 정보",
  "产品详情": "제품 상세 정보",
  "立即购买": "지금 구매",
  "公告列表": "게시 목록",
  "支付方式": "지불 방식",
  "质押数量": "질권 수량",
  "请输入质押数量": "질권 수량을 입력하십시오.",
  "需支付": "지불 필요",
  "USDT余额": "USDT 잔액",
  "质押记录": "질권 기록",
  "支付金额": "지급 금액",
  "XAU总算力": "XAU 총산력",
  "XAU已释放算力": "XAU 계산력 방출",
  "质押时间": "질권 시간",
  "释放中": "석방 중",
  "已结束": "종료",
  "获取算力质押挖矿": "계산력 질권 채굴 획득",
  "当前算力倍数": "현재 계산력 배수",
  "累计产出": "누적 산출",
  "个人算力": "개인 계산력",
  "布道产出": "설교 산출",
  "选择矿场": "광산 선택",
  "挖矿": "수령",
  "此矿场已选择": "이 광산 선택",
  "请选择矿场": "광산을 선택하세요",
  "确认订单": "주문 확인",
  "默认": "기본",
  "请添加收货地址": "배송 주소를 추가하십시오.",
  "提交订单": "주문 전송",
  "请输入交易密码": "거래 암호를 입력하십시오.",
  "链游": "체인 투어",
  "资产": "자산",
  "人民币": "인민폐",
  "充值": "충전",
  "提现": "현금 인출",
  "链上提取": "체인 추출",
  "兑换": "환전",
  "手续费": "수수료",
  "请输入兑换数量": "교환 수량을 입력하세요",
  "当前账户": "현재 계정",
  "请输入充值数量": "충전 수량을 입력하세요",
  "充值记录": "충전 기록",
  "地址": "주소",
  "充值数量": "충전 수량",
  "到账中": "장부에 올리다",
  "已到账": "입금됨",
  "到账失败": "입금 실패",
  "失败原因": "실패 원인",
  "时间": "시간",
  "互转": "상호 전환",
  "转入地址": "이체 계정",
  "请输入转入地址": "이체 계정을 입력하십시오.",
  "USDT最小转账数量": "USDT 최소 이체 수",
  "XAU最小转账数量": "XAU 최소 이체 수량",
  "请输入互转数量": "상호 전달 수량을 입력하십시오.",
  "转入": "전입",
  "钱包": "지갑",
  "互转数量": "상호 전달 수량",
  "实际支付": "실제 지불",
  "钱包明细": "지갑 명세",
  "提现地址": "현금 인출 주소",
  "请输入提现地址": "현금 인출 주소를 입력하십시오.",
  "提现时间": "현금 인출 시간",
  "USDT最小提现数量": "USDT 최소 현금 인출",
  "请输入提现数量": "현금 인출 수량을 입력하십시오.",
  "金额": "금액",
  "实际到账": "실제 입금",
  "待审核": "보류 중",
  "已通过": "통과",
  "已驳回": "기각됨",
  "我的": "나의",
  "级别": "수준",
  "我的订单": "내 주문",
  "待付款": "지불 대기",
  "待发货": "배송 대기 중",
  "待收货": "미수품",
  "待寄售": "위탁 판매 대기",
  "全部订单": "전체 주문",
  "市场管理": "내 팀",
  "收货地址": "배송 주소",
  "钱包转账": "지갑 이체",
  "加密社交": "암호화된 소셜",
  "我的节点": "내 노드",
  "交易中心": "거래 센터",
  "编辑收货地址": "배송 주소 편집",
  "请选择": "선택하십시오.",
  "修改成功": "수정 성공",
  "添加成功": "추가 성공",
  "删除成功": "삭제 성공",
  "订单详情": "주문 상세 정보",
  "商家正在打包中，请耐心等待": "상인은 지금 포장 중이니 참을성 있게 기다려 주십시오",
  "您的快递正在飞奔向您，请耐心等待": "당신의 택배가 당신에게 달려가고 있습니다. 기다려 주십시오",
  "已完成": "완료됨",
  "订单已完成，期待下次下单": "주문 완료, 다음 주문 기대",
  "订单编号": "주문 번호",
  "下单时间": "주문 시간",
  "付款时间": "결제 시간",
  "发货时间": "배송 시간",
  "快递公司": "택배 회사",
  "快递单号": "택배 송장 번호",
  "完成时间": "완료 시간",
  "数量": "수량",
  "商品金额": "상품 금액",
  "确认收货": "수령 확인",
  "收货中": "납품 중",
  "收货成功": "납품 성공",
  "全部": "모두",
  "邀请码": "초대 코드",
  "复制并保存": "복사 및 저장",
  "分享人数": "인원수를 직접 추천하다.",
  "市场人数": "팀 수",
  "个人业绩": "개인 실적",
  "市场业绩": "팀 실적",
  "小市场业绩": "소시장 실적",
  "分享列表": "직추 목록",
  "钱包地址": "지갑 주소",
  "提交成功": "제출 성공",
  "互转记录": "상호 전환 기록",
  "提现记录": "환금 기록",
  "充值记录": "충전 기록",
  "请切换OKT链": "OKT 체인을 변경하십시오.",
  "检测到您已添加OKT Chain，是否切换到该网络？": "OKT 체인을 추가했습니다. 해당 네트워크로 변경하시겠습니까?",
  "体验矿机": "체험 광산",
  "登录成功": "로그인 성공",
  "敬请期待": "곧 기대하십시오",
  "正在开发中": "개발 중",
  "选择数量": "수량 선택",
  "库存": "재고",
  "算力的": "계산력의",
  "AI智能短剧": "AI 스마트 단막극",
  "ROSELLE交易所": "ROSELLE 거래소",
  "联合资产": "공동 자산",
  "XAU链游": "XAU 체인 투어",
  "加密社交": "암호화된 소셜",
  "元宇宙WEB3商城": "메타우주 WEB3 쇼핑몰",
  "公司简介": "회사 소개",
  "客服": "고객",
  "达成条件": "조건을 달성하다",
  "个人业绩累计": "개인 실적 누적",
  "团队业绩累计": "팀 실적 누적",
  "团队伞下节点数": "팀 우산 아래 노드 수",
  "当前级别": "현재 수준",
  "节点": "노드",
  "超级节点": "하이퍼노드",
  "选择级别": "수준 선택",
  "选择区域": "영역 선택",
  "申请": "신청",
  "驳回原因": "기각 원인",
  "审核时间": "감사 시간",
  "请选择级别": "레벨을 선택하십시오.",
  "请选择区域": "영역 선택",
  "OKT最小转账数量": "OKT 최소 이체 수",
  "授权登录": "권한 로그인",
}