<template>
  <div class='index'>
    <nav-bar :title="$t('商品详情')" left-arrow />
    <div class="header">
      <van-swipe class="myswipe" :autoplay="3000" indicator-color="#E9DBFF">
        <van-swipe-item v-for="val in slider" :key="val">
          <img :src="val" alt>
        </van-swipe-item>
      </van-swipe>
      <div class="minbox">
        <div class="infos">
          <p class="name">{{ info.name }}</p>
          <div class="pirce">{{ info.price || 0 }}</div>
        </div>
      </div>
    </div>
    <div class="bodybox">
      <div class="tipbox">
        <span></span>
        <text-p>产品详情</text-p>
        <span></span>
      </div>
      <div class="contentbox htmlcontentbox" v-html="info.content"></div>
    </div>
    <van-goods-action>
      <div class="btns">
        <van-button block @click="buy_show = true">{{ $t('立即购买') }}</van-button>
      </div>
    </van-goods-action>

    <van-popup v-model="buy_show" round :closeable="true" position="bottom" :safe-area-inset-bottom='true'>
      <div class="popup">
        <text-p class="tit">选择数量</text-p>
        <div class="listbox">
          <img :src="info.image" alt />
          <div>
            <p>{{ info.price }} </p>
            <div class="stepper">
              <p>{{ $t('库存') }}:{{ info.stock }}</p>
              <van-stepper disable-input v-model="pay_num" :max="info.stock" integer />
            </div>
          </div>
        </div>
        <div class="btnbox">
          <van-button round type="primary" size="large" @click="setorder">{{ $t('确定') }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.$route.query.id,
        slider: [],
        info: {},
        buy_show: false,
        pay_num: 1,
      }
    },
    created() {
      window.scrollTo(0, 0)
      this.getdetails()
    },
    methods: {
      getdetails() {
        this.$post({
          loading: true,
          url: `/app/mallGoods/info`,
          data: { id: this.id },
          success: (res) => {
            this.slider = res.data.slider
            this.info = res.data
          },
          tip: () => { },
        })
      },
      setorder() {
        this.$router.push({
          name: "suborder",
          query: { id: this.id, num: this.pay_num },
        })
      },
    },
  }
</script>
<style scoped lang='less'>
  .index {
    width: 100%;
    min-height: 100vh;
    padding: 0 0 100px;
    .header {
      width: 100%;
      .myswipe {
        width: 100%;
        /deep/.van-swipe-item {
          width: 100%;
          >img {
            width: 100%;
            height: 375px;
            object-fit: contain;
          }
        }
      }
      .minbox {
        width: 100%;
        padding: 15px;
        .infos {
          width: 100%;
          background: url('../../../assets/img/aa.png') no-repeat;
          background-size: 100% 100%;
          border-radius: 16px;
          .name {
            width: 100%;
            font-size: 16px;
            font-weight: 550;
            line-height: 1.5;
            padding: 10px;
          }
          .pirce {
            width: 100%;
            padding: 0 10px 10px;
            color: #FFFFFF;
            font-size: 24px;
            font-weight: 550;
          }
        }
        .itembox {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          color: #030303;
          font-size: 14px;
          padding: 10px 0 0;
          >div {
            padding: 0 20px 15px 0;
          }
        }
      }
    }
    .heng {
      width: 100%;
      height: 20px;
    }
    .bodybox {
      width: 100%;
      padding: 10px 15px;
      .tipbox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0 15px;
        span {
          width: 15px;
          height: 1px;
          background: #fff;
        }
        p {
          padding: 0 20px;
          color: #fff;
          font-size: 15px;
          font-weight: 550;
        }
      }
      .contentbox {
        width: 100%;
        color: #fff;
        font-size: 14px;
      }
    }
    /deep/.van-goods-action {
      background: none;
    }
    .btns {
      width: 100vw;
      padding: 10px 15px;
      /deep/.van-button {
        border-radius: 10px;
      }
    }
    .popup {
      background: #0D0625;
      padding: 10px;
      .tit {
        padding: 2vw 0;
        width: 100%;
        text-align: center;
        font-size: 4.5vw;
        color: #fff;
      }
      .listbox {
        width: 100%;
        padding: 15px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >img {
          width: 80px;
          height: 80px;
          border-radius: 10px;
          object-fit: contain;
        }
        >div {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding-left: 15px;
          >p {
            width: 100%;
            text-align: left;
            font-size: 4vw;
            color: #fff;
            font-weight: 550;
            margin-bottom: 10px;
          }
          .stepper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            >p {
              font-size: 14px;
            }
            /deep/.van-stepper {
              display: flex;
            }
          }
        }
      }
      .btnbox {
        width: 100%;
        padding: 15px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
</style>
