<template>
  <div class='indexbox'>
    <nav-bar :title="$t('我的订单')" left-arrow />
    <van-tabs sticky @click="onClick" v-model="active" offset-top="45" color="#E9DBFF" title-active-color="#E9DBFF"
      title-inactive-color="#fff">
      <van-tab :title="$t(val.tit)" v-for="val in statuslist" :key="val.ind">
        <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
          <div class="item" v-for="val in list" :key="val.id" @click="godel(val)">
            <div class="topbox">
              <p><text-span>订单编号</text-span>{{ val.order_no }}</p>
              <text-p v-if="val.status == 1">待付款</text-p>
              <text-p v-if="val.status == 2">待发货</text-p>
              <text-p v-if="val.status == 3">待收货</text-p>
              <text-p v-if="val.status == 4">已完成</text-p>
            </div>
            <div class="goodsbox">
              <img v-lazy="val.image" alt="">
              <div class="right">
                <p class="name two">{{ val.goods_name }}</p>
                <p class="price"> {{ val.pay_money }}</p>
              </div>
            </div>
            <div class="btnbox" v-if="val.status == 3">
              <van-button round size="small" color="#73A3FF">{{ $t('确认收货') }}</van-button>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        statuslist: [{ tit: '全部', ind: 0 }, { tit: '待发货', ind: 2 }, { tit: '待收货', ind: 3 }, { tit: '已完成', ind: 4 }],
        active: 0,
        list: [],
        page: 1,
        finished: false,
        loading: false,
      }
    },
    created() {
      if (this.session.get('orderlist_index')) {
        this.active = Number(this.session.get('orderlist_index'))
      }
    },
    methods: {
      godel(val) {
        this.$router.push({ name: "orderdel", query: { id: val.id } })
      },
      onClick(index) {
        this.session.set('orderlist_index', index)
        this.page = 1
        this.list = []
        this.finished = false
        this.onLoad()
      },
      onLoad() {
        var page = this.page++
        this.$post({
          url: '/app/mallOrders/myOrders',
          data: {
            status: this.statuslist[this.active].ind,
            page: page
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var ret = res.data.data
              if (page == 1) {
                this.list = ret
              } else {
                for (var x in ret) {
                  this.list.push(ret[x])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    :deep(.van-tabs__nav) {
      background: #070226;
    }
    /deep/.van-list {
      width: 100%;
      padding: 10px 15px;
    }
    /deep/.van-tab {
      font-weight: 550;
      font-size: 13px;
    }
    .item {
      width: 100%;
      margin-bottom: 20px;
      background: linear-gradient(90deg, #320273 0%, #070226 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      padding: 0 15px 5px;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        p {
          font-weight: 550;
          font-size: 12px;
          &:nth-child(2) {
            color: #FF1E00;
            font-size: 14px;
          }
        }
      }
      .goodsbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 10px;
        >img {
          width: 85px;
          height: 85px;
          border-radius: 8px;
        }
        .right {
          width: 70%;
          min-height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .name {
            width: 100%;
            font-size: 14px;
            font-weight: 550;
            line-height: 1.5;
          }
          .price {
            width: 100%;
            font-size: 16px;
            font-weight: 550;
          }
        }
      }
      .btnbox {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 0;
        /deep/.van-button {
          padding: 3px 20px 2px;
          height: 33px;
          border-radius: 30px;
          .van-button__text {
            color: #fff;
            font-size: 13px;
            font-weight: 550;
          }
        }
      }
    }
  }
</style>