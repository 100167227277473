<template>
  <div class='indexbox'>
    <nav-bar :title="$t('确认订单')" left-arrow />
    <div class="addresbox" @click="router('addresslist')">
      <div class="address" v-if="address?.id">
        <div class="top">
          <span>{{ $t('默认') }}</span>
          <span>{{ address.area_desc }}</span>
        </div>
        <div class="detail">{{ address.detail }}</div>
        <div class="name">{{ address.name }} {{ address.mobile }}</div>
      </div>
      <div class="address" v-else>
        <div class="top">
          <span></span>
          <text-span>请添加收货地址</text-span>
        </div>
      </div>
      <img src="@/assets/img/j.png" alt="">
    </div>

    <div class="goodbox">
      <img :src="info.image" alt="">
      <div class="right">
        <p class="name one">{{ info.name }}</p>
        <p class="price">{{ info.price }}</p>
      </div>
    </div>

    <div class="info">
      <div class="tit">
        <p>XAU</p>
        <p>{{ totalprice }}</p>
      </div>
    </div>

    <van-submit-bar :button-text="$t('提交订单')" @submit="onSubmit" />

    <!--    <van-submit-bar :button-text="$t('提交订单')" @submit="show = true" />
    <van-popup v-model="show" style="width:80%" closeable close-on-popstate>
      <div class="popupbox">
        <!~~ <div class="name">{{ $t('请输入交易密码') }}</div> ~~>
        <div>
          <div class="tit"></div>
          <div class="price"><span></span> {{ info.price }}</div>
        </div>
        <!~~ <van-field v-model="password" input-align="center" maxlength="6" type="password" :placeholder="$t('请输入交易密码')" /> ~~>
        <van-button type="primary" :loading="loading" @click="onSubmit">{{ $t('确定') }}</van-button>
      </div>
    </van-popup>-->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        id: this.$route.query.id,
        num: this.$route.query.num,
        info: {},
        address: {},
        totalprice: 0,
        show: false,
        password: '',
        pay_type: 1,
        infos: {},
        loading: false,
      }
    },
    created() {
      this.getdetails()
    },
    methods: {
      getdetails() {
        this.$post({
          loading: true,
          url: `/app/mall_goods/info`,
          data: { id: this.id },
          success: (res) => {
            this.info = res.data
            this.totalprice = Number(Number(this.info.price) * Number(this.num)).toFixed(2)
            this.get_address()
          },
          tip: () => { },
        })
      },
      get_address() {
        this.$post({
          loading: true,
          url: "/app/member_address/addressList",
          data: { page: 1 },
          success: (res) => {
            if (!res.data.data.length) {
              this.$dialog.alert({
                message: this.$t('请添加收货地址'),
              }).then(() => {
                this.$router.push('addresslist')
              });
            } else {
              this.address = res.data.data[0];
            }
          },
        });
      },
      onSubmit() {
        if (!this.address?.id) return this.$toast(this.$t('请添加收货地址'))
        // if (!this.password) return this.$toast(this.$t('请输入交易密码'))
        this.loading = true
        this.$post({
          loading: true,
          url: '/app/mallOrders/createOrder',
          data: {
            goods_id: this.id,
            num: this.num,
            address_id: this.address.id,
            // password: this.password,
          },
          success: (res) => {
            this.loading = false
            this.password = ''
            this.show = false
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.session.set('orderlist_index', 0)
                this.$router.replace('orderlist')
              }
            })
          },
          tip: () => {
            setTimeout(() => {
              this.loading = false
            }, 1200);
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 15px;
    .addresbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 15px 4px 4px;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      >img {
        width: 12px;
        height: 12px;
      }
      .address {
        width: 100%;
        padding: 18px 8px;
        .top {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            font-size: 16px;
            font-weight: 550;
            &:nth-child(1) {
              color: #FFFFFF;
              font-size: 12px;
              background: @theme;
              border-radius: 2px;
              padding: 2px 4px;
              margin-right: 10px;
              color: #000;
            }
          }
        }
        .detail {
          width: 100%;
          font-size: 16px;
          font-weight: 550;
          padding: 5px 0;
        }
        .name {
          font-size: 13px;
        }
      }
    }
    .goodbox {
      width: 100%;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      margin: 15px 0;
      img {
        height: 85px;
        width: 85px;
        border-radius: 8px;
      }
      .right {
        width: 70%;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .name {
          width: 100%;
          font-size: 14px;
          font-weight: 550;
        }
        .price {
          width: 100%;
          color: @theme;
          font-size: 16px;
          font-weight: 550;
        }
      }
    }
    .info {
      width: 100%;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      padding: 15px;
      .tit {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >p {
          font-size: 14px;
          font-weight: 550;
          &:nth-child(2) {
            max-width: 50%;
            font-size: 16px;
            text-align: right;
          }
        }
      }
    }
    :deep(.van-submit-bar) {
      background: none;
    }
  }
  /deep/.van-popup {
    background: linear-gradient(90deg, #320273 0%, #070226 100%);
    border-radius: 8px;
    border: 1px solid #7F4AF4;
    .popupbox {
      width: 100%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      border-radius: 6px;
      .name {
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 550;
        padding: 15px 0 20px;
      }
      .tit {
        width: 100%;
        text-align: center;
        font-size: 14px;
      }
      .price {
        width: 100%;
        text-align: center;
        padding: 6px 0 20px;
        font-weight: 550;
        font-size: 36px;
        span {
          font-size: 27px;
        }
      }
      .van-cell {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 20px;
        border: 1px solid #7F4AF4;
        .van-field__control {
          color: #000;
        }
      }
      .van-button {
        width: 159px;
        height: 45px;
      }
    }
  }
</style>