<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('提现')" :right-text="$t('记录')" @click-right="router('withdrawallist')" />
    <div class="box">
      <div class="add">
        <text-p class="t">提现地址</text-p>
        <van-field v-model="address" type="textarea" rows="2" readonly :placeholder="$t('请输入提现地址')" clearable
          :border="false" />
      </div>
      <div class="tab">
        <div :class="{ act: balance_type == 1 }" @click="balance_type = 1">USDT</div>
        <div :class="{ act: balance_type == 4 }" @click="balance_type = 4">OKT</div>
      </div>
      <div>
        <p class="t"> <text-span>提现时间</text-span>：{{ config.start_time }}~{{ config.end_time }}</p>
        <p class="t">
          <text-span>USDT最小提现数量</text-span>：{{ config.withdraw_min_u || 0 }}
          <text-span style="margin-left: 30px;">手续费</text-span>：{{ config.withdraw_fee_u || 0 }}%
        </p>
      </div>
      <van-field v-model="amount" type="number" :placeholder="$t('请输入提现数量')" clearable :border="false" />

      <van-button round block :loading="loading" @click="submit">{{ $t('确定') }}</van-button>
    </div>
    <div class="tit">
      <span class="heng"></span><text-span>提现</text-span><text-span>记录</text-span>
    </div>

    <div class="item" v-for="val in list" :key="val.id">
      <div class="l">
        <p>{{ val.amount }}
          <template v-if="val.balance_type == 1">USDT</template>
          <template v-if="val.balance_type == 4">OKT</template>
        </p>
        <p>{{ val.create_time }}</p>
      </div>
      <div class="n">
        <text-p v-if="val.status == 0">待审核</text-p>
        <text-p v-if="val.status == 1">已通过</text-p>
        <text-p v-if="val.status == 2">已驳回</text-p>
      </div>
    </div>
    <text-p class="nodata" v-if="!list.length">没有更多了</text-p>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        config: {},
        address: '',
        amount: '',
        balance_type: 1,//1U 4OKT
        loading: false,
        list: [],
      }
    },
    created() {
      this.getdata()
      this.onLoad()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.address = res.data.account
          },
          tip: () => { },
        });

        this.$get({
          loading: true,
          url: "/app/withdraw/getConfig",
          success: (res) => {
            this.config = res.data
          },
          tip: () => { },
        });
      },
      onLoad() {
        this.$get({
          url: "/app/withdraw/pageList",
          data: {
            page: 1,
          },
          success: (res) => {
            this.list = res.data.data
          },
          tip: () => { },
        })
      },
      submit() {
        if (!this.amount) return this.$toast(this.$t('请输入提现数量'))
        this.loading = true
        this.$post({
          loading: true,
          url: "/app/withdraw/submit",
          data: {
            address: this.address,
            amount: this.amount,
            balance_type: this.balance_type,
          },
          success: (res) => {
            this.amount = ''
            this.loading = false
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.onLoad()
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 10px 15px;
    .box {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      padding: 20px 15px;
      .add {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .5) 0%, rgba(140, 38, 255, .5) 100%);
        border-radius: 8px;
        padding: 10px;
        font-size: 14px;
        color: #D1B6FF;
      }
      .t {
        margin-bottom: 10px;
      }
      .tab {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        font-size: 18px;
        padding: 20px 0;
        >div {
          width: 30%;
          text-align: center;
          padding: 6px;
          border-radius: 8px;
          border: 1px solid #EDE5FF;
          margin-right: 15px;
        }
        .act {
          background: url('../../../assets/img/o.png') no-repeat;
          background-size: 30px 30px;
          background-position: bottom right;
          color: #A874FF;
          border-color: #A874FF;
        }
      }
      :deep(.van-field) {
        background: none;
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin-bottom: 30px;
      }
    }
    .tit {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .heng {
        width: 3px;
        height: 15px;
        background: linear-gradient(135deg, #73A3FF 0%, #8C26FF 100%);
        border-radius: 2px;
        margin-right: 8px;
      }
      span {
        font-size: 17px;
      }
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      margin-bottom: 15px;
      padding: 10px 15px;
      .l {
        width: 80%;
        p {
          width: 100%;
          font-size: 16px;
          font-weight: 550;
          &:nth-child(2) {
            font-size: 13px;
            margin-top: 3px;
            font-weight: 500;
          }
        }
      }
      .n {
        width: 20%;
        text-align: right;
        color: #E760FF;
        font-size: 16px;
        font-weight: 550;
      }
    }
    .nodata {
      width: 100%;
      text-align: center;
    }
  }
</style>