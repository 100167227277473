<template>
  <div class='index'>
    <nav-bar :title="$t('收货地址')" left-arrow />
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
      <van-address-list v-model="id" :list="list" :default-tag-text="$t('默认')" @add="onAdd" @edit="onEdit"
        @select="select" />
    </van-list>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id: '',
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1)
      },
      onLoad() {
        var page = this.page++
        this.$post({
          url: "/app/memberAddress/addressList",
          data: {
            page: page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              res.data.data.forEach((val) => {
                val.tel = val.mobile
                val.address = `${val.area_desc}-${val.detail}`
                val.isDefault = val.is_default == 1 ? true : false
              })
              var list = res.data.data
              let obj = list.find(item => item.is_default == 1)
              if (obj && obj.id) {
                this.id = obj.id
              }
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
      onAdd() {
        this.$router.push("addaddress")
      },
      onEdit(item) {
        this.$router.push({ name: "addaddress", query: { id: item.id } })
      },
      select(data) {
        this.$post({
          loading: true,
          url: `/app/memberAddress/setDefault`,
          data: {
            id: data.id
          },
          success: (res) => {
            this.$toast({
              message: this.$t('设置默认地址成功'),
              onClose: () => {
                this.page = 1
                this.list = []
                this.finished = false
                this.onLoad()
              }
            })
          },
          tip: (val) => { },
        })
      }
    },
  }
</script>

<style scoped lang='less'>
  .index {
    width: 100%;
    min-height: 100vh;
    padding: 0 15px 80px;
    /deep/.van-address-list {
      padding: 0;
      .van-address-item {
        background: linear-gradient(90deg, #320273 0%, #070226 100%);
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin: 0 0 15px;
        .van-cell {
          background: none;
        }
      }
      .van-address-item__name,
      .van-address-item__address {
        color: #fff;
        font-weight: 550;
      }
      .van-address-list__bottom {
        background: none;
      }
    }
  }
</style>
