<template>
  <div class='indexbox'>
    <head-box :address="info.account" />
    <div class="body">
      <div class="num">
        <div class="t">
          <text-span>人民币</text-span>
          <!-- <van-icon name=arrow /> -->
        </div>
        <p>￥：{{ info.total_value || 0 }}</p>
      </div>
      <div class="nav">
        <router-link to="recharge" class="a">
          <img src="@/assets/img/a.png" alt="">
          <text-span>充值</text-span>
        </router-link>
        <router-link to="withdrawal" class="b">
          <img src="@/assets/img/v.png" alt="">
          <text-span>提现</text-span>
        </router-link>
      </div>
      <div class="item" @click="router('walletlist?type=1')">
        <img src="@/assets/img/USDT.png" alt="">
        <p class="a">USDT</p>
        <div class="r">
          <p>{{ info.balance || 0 }}U</p>
          <p>≈¥{{ info.balance_value || 0 }}</p>
        </div>
      </div>
      <div class="item" @click="router('walletlist?type=4')">
        <img src="@/assets/img/okt.png" alt="">
        <p class="a">OKT</p>
        <div class="r">
          <p>{{ info.okt_usdt_value || 0 }}U</p>
          <p>≈¥{{ info.okt_value || 0 }}</p>
        </div>
      </div>
      <div class="item" @click="router('walletlist?type=2')">
        <img src="@/assets/img/logo.png" alt="">
        <p class="a">XAU</p>
        <div class="r">
          <p>{{ info.xau_usdt_value || 0 }}U</p>
          <p>≈¥{{ info.xau_value || 0 }}</p>
        </div>
      </div>
      <div class="item b" @click="router('walletlist?type=3')">
        <img src="@/assets/img/logo.png" alt="">
        <p class="a">XAU<text-span>链上提取</text-span></p>
        <div class="r">
          <p>{{ info.coin_usdt_value || 0 }}U</p>
          <p>≈¥{{ info.coin_value || 0 }}</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        info: {}
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 0 30px;
    .body {
      width: 100%;
      padding: 10px 12px 30px;
      .num {
        width: 100%;
        padding: 15px;
        background: url('../../assets/img/yy.png') no-repeat;
        background-size: 100% 100%;
        .t {
          width: 100%;
          font-size: 14px;
        }
        >p {
          width: 100%;
          text-align: center;
          font-size: 36px;
          font-weight: 550;
        }
      }
      .nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        .a {
          width: 48%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(236, 232, 255, .16);
          border-radius: 25px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
          span {
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 550;
          }
        }
        .b {
          width: 48%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(135deg, #73A3FF 0%, #8C26FF 100%);
          border-radius: 25px;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
          span {
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 550;
          }
        }
      }
      .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
        margin-bottom: 15px;
        padding: 15px;
        img {
          width: 36px;
          height: 36px;
        }
        .a {
          font-size: 21px;
          font-weight: 550;
        }
        .r {
          width: 65%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          >p {
            font-size: 16px;
            &:nth-child(1) {
              color: #A874FF;
              font-size: 24px;
              font-weight: 550;
            }
          }
        }
      }
      .b {
        .a {
          font-size: 13px;
          padding: 0 10px;
        }
        .r {
          flex: 1;
          p {
            width: 100%;
            text-align: right;
          }
        }
      }
    }
  }
</style>