<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('全球矿场')" />
    <div class="body">
      <p class="t">{{ $t('获取算力质押挖矿') }}</p>
      <p class="n"><span>{{ $t('当前算力倍数') }}</span>：{{ info.multiple }}</p>
      <div class="box">
        <text-p>累计产出</text-p>
        <p>{{ info.total || 0 }}</p>
      </div>
      <div class="nav">
        <div>
          <text-p>个人算力</text-p>
          <p>{{ info.power || 0 }}</p>
        </div>
        <div>
          <text-p>布道产出</text-p>
          <p>{{ info.pledge_amount || 0 }}</p>
        </div>
      </div>
      <div class="list">
        <text-p class="tt">选择矿场</text-p>

        <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
          <div class="item" :class="{ active: val.is_select == 1 }" v-for="val in list" :key="val.id"
            @click="change(val)">
            <img src="@/assets/img/kc.png" alt="">
            <p class="nn one">{{ val.name }}</p>
            <div class="r" v-if="val.id == mine_id || val.is_select == 1">
              <span v-if="val.is_select == 1">{{ $t('算力的') }}:{{ val.rate }}%</span>
              <van-icon name="success" size="20" color="#58FFA5" />
            </div>
          </div>
        </van-list>

        <van-button round block @click="submit" :loading="bloading">{{ $t('挖矿') }}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        list: [],
        page: 1,
        loading: false,
        finished: false,
        mine_id: '',
        bloading: false,
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getdata()
    },
    mounted() { },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/member/getPledgeInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      onLoad() {
        let page = this.page++
        this.$post({
          url: '/app/mine/mineList',
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
      change(data) {
        if (data.is_select == 1) return this.$toast(this.$t('此矿场已选择'))
        this.mine_id = data.id
      },
      submit() {
        if (!this.mine_id) return this.$toast(this.$t('请选择矿场'))
        this.bloading = true
        this.$get({
          loading: true,
          url: "/app/memberMine/selectMine",
          data: { mine_id: this.mine_id },
          success: (res) => {
            this.bloading = false
            this.mine_id = ''
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.getdata()
                this.page = 1
                this.finished = false
                this.onLoad()
              }
            })
          },
          tip: () => {
            this.bloading = false
          },
        });
      },

    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background: url('../../../assets/img/bg@2.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 0 40px;
    .body {
      width: 100%;
      padding: 30px 12px;
      .t {
        width: 100%;
        text-align: center;
        font-size: 32px;
        font-weight: 550;
      }
      .n {
        width: 100%;
        text-align: center;
        font-size: 18px;
        padding: 6px 0;
      }
      .box {
        width: 100%;
        background: linear-gradient(90deg, #4E169B 0%, #23044D 100%);
        border-radius: 8px;
        border: 1px solid rgba(195, 168, 255, 0.2);
        padding: 15px 10px;
        margin-top: 40px;
        p {
          width: 100%;
          text-align: center;
          font-size: 14px;
          &:nth-child(2) {
            font-size: 30px;
            font-weight: 550;
            margin-top: 5px;
          }
        }
      }
      .nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        >div {
          width: 48%;
          background: linear-gradient(90deg, #4E169B 0%, #23044D 100%);
          border-radius: 8px;
          border: 1px solid rgba(195, 168, 255, 0.2);
          padding: 15px 10px;
          p {
            width: 100%;
            text-align: center;
            font-size: 14px;
            &:nth-child(2) {
              font-size: 26px;
              font-weight: 550;
              margin-top: 5px;
              line-height: 1.2;
            }
          }
        }
      }
      .list {
        width: 100%;
        background: linear-gradient(90deg, #4E169B 0%, #23044D 100%);
        border-radius: 8px;
        border: 1px solid rgba(195, 168, 255, 0.2);
        padding: 15px 10px 2px;
        .tt {
          width: 100%;
          font-size: 17px;
        }
        .item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
          border-radius: 8px;
          margin-top: 15px;
          padding: 7px 10px;
          border: 1px solid rgba(140, 38, 255, .2);
          img {
            width: 31px;
            height: 26px;
          }
          .nn {
            width: 46%;
            padding: 0 10px;
            font-size: 15px;
            font-weight: 550;
          }
          .r {
            width: 43%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            >span {
              max-width: 90%;
              color: #58FFA5;
              font-size: 14px;
              padding-right: 10px;
            }
          }
        }
        .active {
          background: linear-gradient(135deg, #73A3FF 0%, #8C26FF 100%);
        }
      }
      :deep(.van-button) {
        margin: 10px 0 30px;
        height: 50px;
        .van-button__text {
          font-size: 28px;
        }
      }
    }
  }
</style>