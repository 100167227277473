<template>
  <div class='indexbox'>
    <nav-bar :title="$t('互转')" :right-text="$t('记录')" @click-right="router('turnlist')" />
    <div class="box">
      <div class="add">
        <text-p class="t">转入地址</text-p>
        <van-field v-model="account" type="textarea" rows="3" :placeholder="$t('请输入转入地址')" clearable :border="false" />
      </div>

      <div class="tab">
        <div :class="{ act: balance_type == 1 }" @click="balance_type = 1">USDT</div>
        <div :class="{ act: balance_type == 2 }" @click="balance_type = 2">XAU</div>
        <div :class="{ act: balance_type == 4 }" @click="balance_type = 4">OKT</div>
      </div>

      <div class="t" v-if="balance_type == 1">
        <p>USDT:{{ info.balance }}</p>
        <text-span>USDT最小转账数量</text-span>：{{ config.transfer_min_u || 0 }}
      </div>

      <div class="t" v-if="balance_type == 2">
        <p>XAU:{{ info.xau }}</p>
        <text-span>XAU最小转账数量</text-span>：{{ config.transfer_min_xau || 0 }}
        <text-span style="margin-left: 30px;">手续费</text-span>：{{ config.transfer_fee_xau || 0 }}%
      </div>

      <div class="t" v-if="balance_type == 4">
        <p>OKT:{{ info.okt }}</p>
        <text-span>OKT最小转账数量</text-span>：{{ config.transfer_min_okt || 0 }}
        <text-span style="margin-left: 30px;">手续费</text-span>：{{ config.transfer_fee_okt || 0 }}%
      </div>

      <van-field v-model="amount" type="number" :placeholder="$t('请输入互转数量')" clearable :border="false" />
      <van-button round block :loading="loading" @click="submit">{{ $t('确定') }}</van-button>
    </div>
  </div>
</template>
<script>
  import { Info } from 'vant';

  export default {
    data() {
      return {
        info: {},
        config: {},
        account: '',
        amount: '',
        balance_type: 1,
        loading: false,
      }
    },
    created() {
      this.getinfo()
      this.getdata()
    },
    methods: {
      getinfo() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      getdata() {
        this.$get({
          loading: true,
          url: "/app/transfer/getConfig",
          success: (res) => {
            this.config = res.data
          },
          tip: () => { },
        });
      },
      submit() {
        if (!this.account) return this.$toast(this.$t('请输入转入地址'))
        if (!this.amount) return this.$toast(this.$t('请输入互转数量'))
        // if (this.balance_type == 1) {
        //   if (Number(this.amount) < Number(this.config.transfer_min_u)) return this.$toast(this.$t('USDT最小转账数量') + this.config.transfer_min_u)
        // }
        // if (this.balance_type == 2) {
        //   if (Number(this.amount) < Number(this.config.transfer_min_xau)) return this.$toast(this.$t('XAU最小转账数量') + this.config.transfer_min_xau)
        // }
        // if (this.balance_type == 4) {
        //   if (Number(this.amount) < Number(this.config.transfer_min_okt)) return this.$toast(this.$t('OKT最小转账数量') + this.config.transfer_min_okt)
        // }

        this.loading = true
        this.$post({
          loading: true,
          url: "/app/transfer/transferDo",
          data: {
            account: this.account,
            amount: this.amount,
            balance_type: this.balance_type,
          },
          success: (res) => {
            this.account = ''
            this.amount = ''
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.getinfo()
                this.loading = false
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 10px 15px 30px;
    .box {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      padding: 20px 15px;
      .add {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .5) 0%, rgba(140, 38, 255, .5) 100%);
        border-radius: 8px;
        padding: 10px;
      }
      .t {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        p {
          width: 100%;
        }
      }
      .tab {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 18px;
        padding: 20px 0;
        >div {
          width: 30%;
          text-align: center;
          padding: 6px;
          border-radius: 8px;
          border: 1px solid #EDE5FF;
        }
        .act {
          background: url('../../assets/img/o.png') no-repeat;
          background-size: 30px 30px;
          background-position: bottom right;
          color: #A874FF;
          border-color: #A874FF;
        }
      }
      :deep(.van-field) {
        background: none;
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin-bottom: 30px;
      }
    }
  }
</style>
