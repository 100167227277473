<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('兑换')" />
    <div class="box">
      <div class="add">
        <div>
          <p>XAU</p>
          <p>{{ info.xau || 0 }}</p>
        </div>
        <div>
          <p>OKT</p>
          <p>{{ info.okt || 0 }}</p>
        </div>
        <div>
          <p>USDT</p>
          <p>{{ info.balance }}U</p>
        </div>
      </div>
      <div class="tab">
        <div>XAU</div>
        <div class="m">
          <img src="@/assets/img/j.png" alt="">
          <img src="@/assets/img/j.png" alt="">
        </div>
        <van-popover v-model="showPopover" theme="dark" trigger="click" :actions="actions" placement="bottom-end"
          @select="change">
          <template #reference>
            <div class="toname">
              {{ to_name }}
              <van-icon name="arrow" color="#fff"></van-icon>
            </div>
          </template>
        </van-popover>
      </div>
      <p class="t">
        <text-span>手续费</text-span>：{{ config.exchange_fee || 0 }}%
      </p>
      <van-field v-model="amount" type="number" :placeholder="$t('请输入兑换数量')" clearable :border="false" />
      <van-button round block :loading="loading" @click="submit">{{ $t('确定') }}</van-button>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        showPopover: false,
        actions: [{ text: 'USDT', type: 1 }, { text: 'OKT', type: 2 }],//1 XAU兑换USDT 2 XAU兑换OKT
        config: {},
        info: {},
        amount: '',
        to_name: 'USDT',
        type: 1,
        loading: false,
      }
    },
    created() {
      this.getdata()
      this.getinfo()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/exchange/getConfig",
          success: (res) => {
            this.config = res.data
          },
          tip: () => { },
        });
      },
      getinfo() {
        this.$get({
          loading: true,
          url: "/app/member/memberInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      change(action) {
        this.to_name = action.text
        this.type = action.type
      },
      submit() {
        if (!this.amount) return this.$toast(this.$t('请输入兑换数量'))
        this.loading = true
        this.$post({
          loading: true,
          url: "/app/exchange/exchangeDo",
          data: {
            amount: this.amount,
            type: this.type,
          },
          success: (res) => {
            this.amount = ''
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.loading = false
                this.getinfo()
              }
            })
          },
          tip: () => {
            this.loading = false
          },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 10px 15px;
    .box {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      padding: 20px 15px;
      .add {
        width: 100%;
        background: linear-gradient(135deg, rgba(115, 163, 255, .5) 0%, rgba(140, 38, 255, .5) 100%);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        >div {
          width: 33%;
          padding: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            font-size: 13px;
            &:nth-child(2) {
              font-size: 16px;
              font-weight: 550;
            }
          }
        }
      }
      .t {
        margin-bottom: 15px;
      }
      .tab {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 18px;
        padding: 20px 0;
        >div {
          width: 30%;
          text-align: center;
          padding: 6px;
          border-radius: 8px;
          border: 1px solid #EDE5FF;
        }
        .toname {
          padding: 6px 20px;
          border-radius: 8px;
          border: 1px solid #EDE5FF;
        }
        .m {
          border: none;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      :deep(.van-field) {
        background: none;
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        margin-bottom: 30px;
      }
    }
  }
</style>