<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('我的节点')" />
    <div class="topbox">
      <text-p class="t">达成条件</text-p>

      <div class="it" v-for="val in nodeList" :key="val.id">
        <p class="l">{{ val.node_name }}</p>
        <div class="r">
          <div>
            <p><text-span>个人业绩累计</text-span>{{ val.self_amount }}U</p>
            <img src="@/assets/img/dui.png" alt="" v-if="Number(val.self_amount) <= Number(info.self_amount)">
            <img src="@/assets/img/cuo.png" alt="" v-else>
          </div>
          <div>
            <p><text-span>团队业绩累计</text-span>{{ val.team_amount }}U</p>
            <img src="@/assets/img/dui.png" alt="" v-if="Number(val.team_amount) <= Number(info.team_amount)">
            <img src="@/assets/img/cuo.png" alt="" v-else>
          </div>
        </div>
      </div>
      <div class="my">
        <text-span>当前级别</text-span>
        <text-span>{{ info.node_name || '-' }}</text-span>
      </div>
    </div>
    <div class="formbox">
      <van-field :label="$t('选择级别')" @click="showa = true" :value="node_name" input-align="right"
        :placeholder="$t('选择级别')" readonly is-link :border="false" />
      <van-field :label="$t('选择区域')" @click="showb = true" :value="area_name" input-align="right"
        :placeholder="$t('选择区域')" readonly is-link :border="false" />
      <van-button round block :loading="bloading" @click="submit">{{ $t('确定') }}</van-button>
    </div>

    <div div class=" tit">
      <span class="heng"></span><text-span>申请</text-span><text-span>记录</text-span>
    </div>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
      <div class="item" v-for="val in list" :key="val.id">
        <div>{{ val.node.node_name }}</div>
        <div>{{ val.area.name_path }}</div>
        <div>
          <text-p v-if="val.status == 0">待审核</text-p>
          <text-p v-if="val.status == 1">已通过</text-p>
          <text-p v-if="val.status == 2">已驳回</text-p>
        </div>
        <div v-if="val.status == 2">
          <text-p>驳回原因</text-p>：{{ val.remark }}
        </div>
        <div class="time" v-if="val.check_time">
          <text-p>审核时间</text-p>：{{ val.check_time }}
        </div>
        <div class="time">
          <text-p>时间</text-p>：{{ val.create_time }}
        </div>
      </div>
    </van-list>

    <van-popup v-model="showa" round position="bottom">
      <van-picker show-toolbar :cancel-button-text="$t('取消')" :confirm-button-text="$t('确定')" :columns="nodeList"
        value-key="node_name" @cancel="showa = false" @confirm="onConfirm" />
    </van-popup>

    <van-popup v-model="showb" round position="bottom">
      <van-area :columns-num="node_id == 2 ? '2' : '3'" :title="$t('选择区域')" :area-list="areaList" @confirm="confirm" />
    </van-popup>
  </div>
</template>
<script>
  import { areaList } from "@vant/area-data"
  export default {
    components: {},
    data() {
      return {
        areaList,
        nodeList: [],
        list: [],
        page: 1,
        loading: false,
        finished: false,
        info: {},

        node_name: '',
        node_id: '',
        showa: false,

        area_name: '',
        area_code: '',
        showb: false,
        bloading: false,
      }
    },
    created() {
      this.getdata()
      this.getlist()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: "/app/node/memberNodeInfo",
          success: (res) => {
            this.info = res.data
          },
          tip: () => { },
        });
      },
      getlist() {
        this.$get({
          loading: true,
          url: "/app/node/nodeList",
          success: (res) => {
            this.nodeList = res.data || res.msg
          },
          tip: () => { },
        });
      },
      onConfirm(value) {
        this.node_id = value.id
        this.node_name = value.node_name
        this.area_name = ''
        this.area_code = ''
        this.showa = false
      },
      confirm(data) {
        if (this.node_id == 2) {
          this.area_name = `${data[0].name}-${data[1].name}`
          this.area_code = data[1].code
        } else {
          this.area_name = `${data[0].name}-${data[1].name}-${data[2].name}`
          this.area_code = data[2].code
        }

        this.showb = false
      },
      submit() {
        if (!this.node_id) return this.$toast(this.$t('请选择级别'))
        if (!this.area_code) return this.$toast(this.$t('请选择区域'))
        this.bloading = true
        this.$post({
          loading: true,
          url: "/app/nodeApply/applyDo",
          data: { node_id: this.node_id, area_code: this.area_code },
          success: (res) => {
            this.bloading = false
            this.node_id = ''
            this.node_name = ''
            this.area_name = ''
            this.area_code = ''
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.getdata()
                this.page = 1
                this.finished = false
                this.onLoad()
              }
            })
          },
          tip: () => {
            this.bloading = false
          },
        });
      },
      onLoad() {
        let page = this.page++
        this.$post({
          url: "/app/nodeApply/applyList",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },

    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 15px 30px;
    .topbox {
      width: 100%;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      padding: 5px 15px 10px;
      border-radius: 8px;
      .t {
        width: 100%;
        font-size: 16px;
        text-align: center;
        font-size: 17px;
        color: #9353FF;
        padding: 10px 0;
        border-bottom: 1px solid #FFFFFF;
      }
      .it {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px 0;
        .l {
          width: 20%;
          padding-top: 2px;
        }
        .r {
          width: 77%;
          font-weight: 550;
          >div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 3px;
            img {
              width: 15px;
              height: 12px;
              object-fit: contain;
            }
          }
        }
      }
      .my {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .formbox {
      width: 100%;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      padding: 5px 15px 10px;
      border-radius: 8px;
      margin: 10px 0;
      :deep(.van-field) {
        background: none;
        padding: 10px 0;
        .van-cell__title,
        .van-field__control {
          color: #Fff;
        }
      }
      :deep(.van-button) {
        margin: 30px 0 10px;
      }
    }
    .tit {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .heng {
        width: 3px;
        height: 15px;
        background: linear-gradient(135deg, #73A3FF 0%, #8C26FF 100%);
        border-radius: 2px;
        margin-right: 8px;
      }
      span {
        font-size: 17px;
      }
    }
    .item {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      margin-top: 15px;
      padding: 10px 15px;
      >div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 15px;
        line-height: 1.7;
        font-weight: 550;
        span {
          color: #BB92FF;
        }
      }
      .time {
        color: #fcfcfc;
      }
    }
  }
</style>