<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('充值记录')" />
    <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
      <div class="item" v-for="val in list" :key="val.id">
        <div>
          <text-p>地址</text-p>{{ val.account }}
        </div>
        <div>
          <text-p>充值数量</text-p>：{{ val.amount }}
          <template v-if="val.type == 1">USDT</template>
          <template v-if="val.type == 2">OKT</template>
        </div>
        <div>
          <p>hash</p>：{{ val.hash }}
        </div>
        <div>
          <text-p v-if="val.status == 1">到账中</text-p>
          <text-p v-if="val.status == 2">已到账</text-p>
          <text-p v-if="val.status == 3">到账失败</text-p>
        </div>
        <div v-if="val.status == 3">
          <text-p>失败原因</text-p>：{{ val.reason }}
        </div>
        <div class="time">
          <text-p>时间</text-p>：{{ val.create_time }}
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    created() {
    },
    methods: {

      onLoad() {
        let page = this.page++
        this.$get({
          url: "/app/recharge/pageList",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },

    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 0 15px 30px;
    .item {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
      margin-top: 15px;
      padding: 10px 15px;
      >div {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 15px;
        line-height: 1.7;
        font-weight: 550;
        span {
          color: #BB92FF;
        }
      }
      .time {
        color: #fcfcfc;
      }
    }
  }
</style>