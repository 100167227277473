module.exports = {
  "中文": "中文",
  "英语": "英语",
  "韩语": "韩语",
  "成功": "成功",
  "失败": "失败",
  "确认": "确认",
  "取消": "取消",
  "确定": "确定",
  "余额不足": "余额不足",
  "没有更多了": "没有更多了",
  "签名失败": "签名失败",
  "详情": "详情",
  "记录": "记录",
  "复制成功": "复制成功",

  "首页": "首页",
  "算力质押": "算力质押",
  "兑换专区": "兑换专区",
  "全球矿场": "全球矿场",
  "邀请好友": "邀请好友",
  "市场动态": "市场动态",
  "商品详情": "商品详情",
  "产品详情": "产品详情",
  "立即购买": "立即购买",
  "公告列表": "公告列表",
  "支付方式": "支付方式",
  "质押数量": "质押数量",
  "请输入质押数量": "请输入质押数量",
  "需支付": "需支付",
  "USDT余额": "USDT余额",
  "质押记录": "质押记录",
  "支付金额": "支付金额",
  "XAU总算力": "XAU总算力",
  "XAU已释放算力": "XAU已释放算力",
  "质押时间": "质押时间",
  "释放中": "释放中",
  "已结束": "已结束",
  "获取算力质押挖矿": "获取算力质押挖矿",
  "当前算力倍数": "当前算力倍数",
  "累计产出": "累计产出",
  "个人算力": "个人算力",
  "布道产出": "布道产出",
  "选择矿场": "选择矿场",
  "挖矿": "挖矿",
  "此矿场已选择": "此矿场已选择",
  "请选择矿场": "请选择矿场",
  "确认订单": "确认订单",
  "默认": "默认",
  "请添加收货地址": "请添加收货地址",
  "提交订单": "提交订单",
  "请输入交易密码": "请输入交易密码",

  "链游": "链游",
  "资产": "资产",
  "人民币": "人民币",
  "充值": "充值",
  "提现": "提现",
  "链上提取": "链上提取",
  "兑换": "兑换",
  "手续费": "手续费",
  "请输入兑换数量": "请输入兑换数量",
  "当前账户": "当前账户",
  "请输入充值数量": "请输入充值数量",
  "充值记录": "充值记录",
  "地址": "地址",
  "充值数量": "充值数量",
  "到账中": "到账中",
  "已到账": "已到账",
  "到账失败": "到账失败",
  "失败原因": "失败原因",
  "时间": "时间",
  "互转": "互转",
  "转入地址": "转入地址",
  "请输入转入地址": "请输入转入地址",
  "USDT最小转账数量": "USDT最小转账数量",
  "XAU最小转账数量": "XAU最小转账数量",
  "请输入互转数量": "请输入互转数量",
  "转入": "转入",
  "钱包": "钱包",
  "互转数量": "互转数量",
  "实际支付": "实际支付",
  "钱包明细": "钱包明细",
  "提现地址": "提现地址",
  "请输入提现地址": "请输入提现地址",
  "提现时间": "提现时间",
  "USDT最小提现数量": "USDT最小提现数量",
  "请输入提现数量": "请输入提现数量",
  "金额": "金额",
  "实际到账": "实际到账",
  "待审核": "待审核",
  "已通过": "已通过",
  "已驳回": "已驳回",

  "我的": "我的",
  "级别": "级别",
  "我的订单": "我的订单",
  "待付款": "待付款",
  "待发货": "待发货",
  "待收货": "待收货",
  "待寄售": "待寄售",
  "全部订单": "全部订单",
  "市场管理": "市场管理",
  "收货地址": "收货地址",
  "钱包转账": "钱包转账",
  "加密社交": "加密社交",
  "我的节点": "我的节点",
  "交易中心": "交易中心",
  "编辑收货地址": "编辑收货地址",
  "请选择": "请选择",
  "修改成功": "修改成功",
  "添加成功": "添加成功",
  "删除成功": "删除成功",
  "订单详情": "订单详情",
  "待发货": "待发货",
  "商家正在打包中，请耐心等待": "商家正在打包中，请耐心等待",
  "待收货": "待收货",
  "您的快递正在飞奔向您，请耐心等待": "您的快递正在飞奔向您，请耐心等待",
  "已完成": "已完成",
  "订单已完成，期待下次下单": "订单已完成，期待下次下单",
  "订单编号": "订单编号：",
  "下单时间": "下单时间：",
  "付款时间": "付款时间：",
  "发货时间": "发货时间：",
  "快递公司": "快递公司：",
  "快递单号": "快递单号：",
  "完成时间": "完成时间：",
  "数量": "数量",
  "商品金额": "商品金额",
  "确认收货": "确认收货",
  "收货中": "收货中...",
  "收货成功": "收货成功",
  "全部": "全部",
  "邀请码": "邀请码：",
  "复制并保存": "复制并保存",
  "分享人数": "分享人数",
  "市场人数": "市场人数",
  "个人业绩": "个人业绩",
  "市场业绩": "市场业绩",
  "小市场业绩": "小市场业绩",
  "分享列表": "分享列表",
  "钱包地址": "钱包地址",
  "提交成功": "提交成功",
  "互转记录": "互转记录",
  "提现记录": "提现记录",
  "充值记录": "充值记录",
  "请切换OKT链": "请切换OKT链",
  "检测到您已添加OKT Chain，是否切换到该网络？": "检测到您已添加OKT Chain，是否切换到该网络？",
  "体验矿机": "体验矿机",
  "登录成功": "登录成功",
  "敬请期待": "敬请期待",
  "正在开发中": "正在开发中",
  "选择数量": "选择数量",
  "库存": "库存",
  "算力的": "算力的",
  "AI智能短剧": "AI智能短剧",
  "ROSELLE交易所": "ROSELLE交易所",
  "联合资产": "联合资产",
  "XAU链游": "XAU链游",
  "加密社交": "加密社交",
  "元宇宙WEB3商城": "元宇宙WEB3商城",
  "公司简介": "公司简介",
  "客服": "客服",
  "达成条件": "达成条件",
  "个人业绩累计": "个人业绩累计",
  "团队业绩累计": "团队业绩累计",
  "团队伞下节点数": "团队伞下节点数",
  "当前级别": "当前级别",
  "节点": "节点",
  "超级节点": "超级节点",
  "选择级别": "选择级别",
  "选择区域": "选择区域",
  "申请": "申请",
  "驳回原因": "驳回原因",
  "审核时间": "审核时间",
  "请选择级别": "请选择级别",
  "请选择区域": "请选择区域",
  "OKT最小转账数量": "OKT最小转账数量",
  "授权登录": "授权登录",
}