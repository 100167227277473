<template>
  <div class='indexbox'>
    <nav-bar :title="$t('市场管理')" left-arrow />
    <div class="numsbox">
      <div class="a">
        <text-p>分享人数</text-p>
        <p>{{ info.redirect_num || 0 }}</p>
      </div>
      <div class="a b">
        <text-p>市场人数</text-p>
        <p>{{ info.team_num || 0 }}</p>
      </div>
      <div class="c">
        <div class="i">
          <p><text-span>个人业绩</text-span>(U)</p>
          <p>{{ Number(info.self_amount || 0).toFixed(2) }}</p>
        </div>
        <div class="i">
          <p><text-span>市场业绩</text-span>(U)</p>
          <p>{{ Number(info.team_amount || 0).toFixed(2) }}</p>
        </div>
        <div class="i">
          <p><text-span>小市场业绩</text-span>(U)</p>
          <p>{{ Number(info.small_amount || 0).toFixed(2) }}</p>
        </div>
      </div>
    </div>
    <div class="body">
      <text-p class="t">分享列表</text-p>
      <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="onLoad" :offset="0">
        <div class="item" v-for="val in list" :key="val.id">
          <p class="tt" v-if="val.level"><text-span>级别</text-span>:{{ val.level }}</p>
          <div class="n">
            <p><text-span>分享人数</text-span>：{{ val.direct_num || 0 }}</p>
            <p><text-span>市场人数</text-span>：{{ val.team_num || 0 }}</p>
            <p class="g"><span>个人业绩</span><span>：</span>{{ Number(val.self_amount || 0).toFixed(2) }}</p>
            <p><text-span>市场业绩</text-span>：{{ Number(val.team_amount || 0).toFixed(2) }}</p>
          </div>
          <p class="at"><text-span>钱包地址</text-span>：</p>
          <p class="add">{{ val.account }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
        list: [],
        page: 1,
        loading: false,
        finished: false,
      }
    },
    filters: {
      tellFun(data) {
        return data.substr(0, 3) + "****" + data.substr(7);
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$post({
          loading: true,
          url: "/app/member/teamInfo",
          success: (res) => {
            this.info = res.data;
          },
          tip: () => { },
        });
      },
      onLoad() {
        let page = this.page++
        this.$post({
          url: "/app/member/teamList",
          data: {
            page,
          },
          success: (res) => {
            if (!res.data || res.data.data.length == 0) {
              this.finished = true
            } else {
              var list = res.data.data
              if (page == 1) {
                this.list = list
              } else {
                for (var i in list) {
                  this.list.push(list[i])
                }
              }
            }
            this.loading = false
          },
          tip: () => {
            this.finished = true
          },
        })
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    padding: 10px 15px 50px;
    .numsbox {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .a {
        width: 48%;
        padding: 17px 15px 10px;
        border-radius: 8px;
        background: url('../../../assets/img/tta.png') no-repeat;
        background-size: 100% 100%;
        margin-bottom: 15px;
        p {
          font-size: 14px;
          &:nth-child(2) {
            margin-top: 3px;
            font-weight: 550;
            font-size: 24px;
          }
        }
      }
      .b {
        background: url('../../../assets/img/w.png') no-repeat;
        background-size: 100% 100%;
      }
      .c {
        width: 100%;
        background: url('../../../assets/img/aa.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        .i {
          max-width: 32%;
          padding: 5px;
          p {
            font-size: 14px;
            &:nth-child(2) {
              margin-top: 3px;
              font-weight: 550;
              font-size: 24px;
            }
          }
        }
      }
    }
    .body {
      width: 100%;
      padding: 0 15px;
      background: linear-gradient(90deg, #4E169B 0%, #23044D 100%);
      border-radius: 8px;
      border: 1px solid rgba(195, 168, 255, 0.2);
      .t {
        width: 100%;
        padding: 10px 0;
        font-size: 16px;
      }
      .item {
        width: 100%;
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        background: linear-gradient(135deg, rgba(115, 163, 255, .2) 0%, rgba(140, 38, 255, .2) 100%);
        position: relative;
        .tt {
          position: absolute;
          top: 0;
          right: 0;
          background: linear-gradient(90deg, #696AF8 0%, #955EE9 100%);
          border-radius: 0px 8px 0px 8px;
          padding: 2px 8px;
          font-size: 13px;
        }
        .n {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          p {
            width: 50%;
            font-weight: 550;
            padding: 2px 2px 2px 0;
          }
        }
        .g {
          color: #fff;
          span {
            color: #fff;
          }
        }
        .at {
          margin-top: 10px;
        }
      }
    }
  }
</style>
