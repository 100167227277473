<template>
  <div class='index'>
    <nav-bar :title="$t('编辑收货地址')" left-arrow />
    <van-address-edit tel-maxlength="11" :area-list="areaList" :address-info='show ? addressinfo : {}' show-set-default
      :area-columns-placeholder="[$t('请选择'), $t('请选择'), $t('请选择')]" @save="onSave" :is-saving='loading'
      :is-deleting='loadinga' :show-delete='show' @delete='getdel' />
  </div>
</template>

<script>
  import { areaList } from "@vant/area-data"
  export default {
    data() {
      return {
        show: false,
        addressinfo: {},
        id: this.$route.query.id,
        areaList,
        loading: false,
        loadinga: false,
      }
    },
    created() {
      if (this.id) {
        this.getdata()
      }
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1)
      },
      onSave(content) {
        this.loading = true
        if (this.id) {
          this.$post({
            url: `/app/memberAddress/edit`,
            data: {
              id: this.id,
              name: content.name,
              mobile: content.tel,
              area_code: content.areaCode,
              detail: content.addressDetail,
              is_default: content.isDefault ? 1 : 0,
            },
            success: () => {
              this.$toast.clear()
              this.loading = false
              this.$toast(this.$t('修改成功'))
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            },
            tip: () => {
              this.loading = false
            },
          })
        } else {
          this.$post({
            url: "/app/memberAddress/add",
            data: {
              name: content.name,
              mobile: content.tel,
              area_code: content.areaCode,
              detail: content.addressDetail,
              is_default: content.isDefault ? 1 : 0,
            },
            success: () => {
              this.loading = false
              this.$toast({
                message: this.$t('添加成功'),
                onClose: () => {
                  this.$router.go(-1)
                }
              })
            },
            tip: () => {
              this.loading = false
            },
          })
        }
      },
      getdata() {
        this.$post({
          url: `/app/memberAddress/info`,
          data: {
            id: this.id,
          },
          success: (res) => {
            this.show = true
            let data = res.data.area_desc.split('-')
            this.addressinfo = {
              id: this.id,
              name: res.data.name,
              tel: res.data.mobile,
              province: data[0],
              city: data[1],
              county: data[2],
              addressDetail: res.data.detail,
              areaCode: res.data.area_code.toString(),
              isDefault: res.data.is_default ? true : false,
            }
          },
          tip: (val) => { },
        })
      },
      getdel() {
        this.loadinga = true
        this.$post({
          loading: true,
          url: `/app/memberAddress/del`,
          data: { id: this.id },
          success: () => {
            this.loadinga = false
            this.$toast({
              message: this.$t('删除成功'),
              onClose: () => {
                this.$router.go(-1)
              }
            })
          },
          tip: () => {
            this.loadinga = false
          },
        })
      },
    },
  }
</script>

<style scoped lang='less'>
  .index {
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 0 15px 50px;
    /deep/.van-address-edit {
      padding: 0;
      .van-address-edit__fields {
        background: linear-gradient(90deg, #320273 0%, #070226 100%);
        border-radius: 8px;
        border: 1px solid #7F4AF4;
        .van-cell {
          padding: 15px;
          background: none;
          .van-cell__title,
          .van-field__control {
            color: #fff;
          }
        }
      }
      .van-address-edit-detail {
        padding: 0 !important;
      }
    }
    :deep(.van-address-edit__default) {
      background: linear-gradient(90deg, #320273 0%, #070226 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      &::after {
        display: none;
      }
      .van-cell__title,
      .van-field__control {
        color: #fff;
      }
    }
    /deep/.van-address-edit__buttons {
      padding: 60px 0;
      .van-button--default {
        background: red;
        color: #ffffff;
      }
    }
    /deep/.van-button--danger {
      border: none !important;
    }
    /deep/.van-switch--on {
      background-color: #A874FF;
    }
  }
</style>
