<template>
  <div class='indexbox'>
    <nav-bar left-arrow :title="$t('详情')" />
    <div class="bodybox">
      <p class="tit">{{ info.title }}</p>
      <p class="time">{{ info.create_time }}</p>
      <div class="msgbox htmlcontentbox" v-html="info.content"></div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        info: {},
      }
    },
    created() {
      this.getdata()
    },
    methods: {
      getdata() {
        this.$get({
          loading: true,
          url: `/app/article/info?id=${this.urlQuery().id}`,
          success: (res) => {
            this.info = res.data;
          },
          tip: () => { },
        });
      },
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    padding: 15px 15px 40px;
    .bodybox {
      width: 100%;
      background: linear-gradient(90deg, #320273 0%, #070226 100%);
      border-radius: 8px;
      border: 1px solid #7F4AF4;
      padding: 20px 10px;
      .tit {
        width: 100%;
        font-size: 18px;
        font-weight: 550;
      }
      .time {
        width: 100%;
        padding: 10px 0;
        font-size: 13px;
      }
      .msgbox {
        width: 100%;
        font-size: 14px;
      }
    }
  }
</style>