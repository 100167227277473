<template>
  <div>
    <van-sticky>
      <div class='indexbox'>
        <div class="l">
          <img src="@/assets/img/logo.png" alt="" class="logo">
          <p class="add one" v-if="address">{{ addressFun(address) }}</p>
        </div>
        <div class="r">
          <van-popover v-model="showPopover" theme="dark" trigger="click" :actions="actions" placement="bottom-end"
            @select="change">
            <template #reference>
              <img src="@/assets/img/f.png" alt="">
            </template>
          </van-popover>
          <van-icon name="wap-nav" color="#fff" size="28" @click="show = true" />
        </div>
      </div>
    </van-sticky>
    <van-popup v-model="show" position="right" closeable :style="{ width: '70%' }">
      <div class="contentbox">
        <img src="@/assets/img/logo@2x.png" alt="" class="logo">
        <div class="addbox">
          <p>{{ addressFun(address) }}</p>
          <van-icon name="orders-o" color="#fff" size="24" @click="copyData(address)" />
        </div>
        <div class="anv" @click="routhome">
          <text-span>首页</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
        <div class="anv" @click="$toast($t('正在开发中'))">
          <text-span>AI智能短剧</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
        <div class="anv" @click="$toast($t('正在开发中'))">
          <text-span>ROSELLE交易所</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
        <div class="anv" @click="$toast($t('敬请期待'))">
          <text-span>联合资产</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
        <div class="anv" @click="$toast($t('敬请期待'))">
          <text-span>XAU链游</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
        <div class="anv" @click="$toast($t('敬请期待'))">
          <text-span>加密社交</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
        <div class="anv" @click="$toast($t('敬请期待'))">
          <text-span>元宇宙WEB3商城</text-span>
          <van-icon name="play" color="#fff" size="20" />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      address: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        showPopover: false,
        actions: [{ text: this.$t('中文'), name: 'zh' }, { text: this.$t('英语'), name: 'en' }, { text: this.$t('韩语'), name: 'ko' }],
        show: false,
      }
    },
    computed: {},
    watch: {
    },
    created() {
      // if (this.session.get("token") && this.$route.name == '/') {
      //   this.show = true
      // }
    },
    mounted() { },
    methods: {
      change(action) {
        this.changeLang(action.name)
      },
      routhome() {
        this.show = false
        this.$router.replace('/')
      }
    },
  }
</script>
<style lang='less' scoped>
  .indexbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    padding: 15px 12px;
    .l {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .logo {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
      .add {
        text-align: center;
        color: #fff;
        font-size: 16px;
        padding: 10px 13px;
        line-height: 1;
        border-radius: 10px;
        background: rgba(236, 232, 255, 0.16);
      }
    }
    .r {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      :deep(.van-popover__wrapper) {
        padding: 2px;
        margin-right: 10px;
      }
      img {
        width: 25px;
        height: 25px;
        object-fit: cover;
      }
    }
  }
  :deep(.van-sticky--fixed) {
    background: #0D0625;
  }
  :deep(.van-popup) {
    width: 100%;
    height: 100%;
    background: #3C0D7E;
    padding: 20px;
    .contentbox {
      width: 100%;
      padding: 30px 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logo {
        width: 55%;
        height: 60px;
        object-fit: contain;
      }
      .addbox {
        width: 100%;
        margin: 10px 0 15px;
        font-size: 16px;
        padding: 10px 13px;
        border-radius: 10px;
        background: rgba(236, 232, 255, 0.16);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .anv {
        width: 100%;
        font-size: 16px;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
</style>