<template>
  <div id="app" v-cloak>
    <transition name="fade" mode="out-in">
      <router-view v-if="show" />
    </transition>
    <div class="box" v-if="!show && chain != 66">{{ $t('请切换OKT链') }}</div>
  </div>
</template>

<script>
  export default {
    name: "app",
    data() {
      return {
        show: false,
        chain: 0,
      };
    },
    methods: {
      getChain() {
        this.$dialog.confirm({
          message: this.$t('检测到您已添加OKT Chain，是否切换到该网络？'),
          confirmButtonText: this.$t('确认'),
          cancelButtonText: this.$t('取消'),
        }).then(() => {
          // on confirm
          this.changeNetwork()
        }).catch(() => {
          // on cancel
        });
      },
      // 切换网络
      async changeNetwork() {
        let web3 = this.$web3Fun.getWeb3();
        let that = this
        if (window.ethereum) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: web3.utils.toHex(66) }],
            });
          } catch (err) {
            console.warn('wallet_switchEthereumChain', err);
            if (err.code === 4902) {
              try {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainName: 'OKT Chain',
                      chainId: web3.utils.toHex(66),
                      nativeCurrency: { name: 'OKT', decimals: 18, symbol: 'OKT' },
                      rpcUrls: ['https://exchainrpc.okex.org/'],
                      blockExplorerUrls: ['https://www.oklink.com/okexchain/']
                    }
                  ]
                });
                that.changetwo()
              } catch (error) {
                console.warn('wallet_addEthereumChain', error);
              }
            }
          }
        }
      },
      async changetwo() {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(66) }],
        });
        this.session.del("token")
        window.location.reload();
      },
    },
    created() {
      let that = this
      let web3 = that.$web3Fun.getWeb3();
      if (process.env.NODE_ENV == 'production') {
        this.session.del("token")
        window.ethereum.request({ method: "eth_chainId" }).then((res) => {
          console.warn('OKT CHAIN_ID', web3.utils.hexToNumberString(res));
          that.chain = web3.utils.hexToNumberString(res)
          if (web3.utils.hexToNumberString(res) == 66) {
            that.show = true
          } else {
            that.show = false
            that.$toast({
              duration: 0,
              type: "fail",
              message: this.$t('请切换OKT链')
            });
            that.getChain()
          }
        });
      } else {
        console.log('test');
        window.ethereum.request({ method: "eth_chainId" }).then((res) => {
          console.warn('OKT CHAIN_ID', web3.utils.hexToNumberString(res));
          that.chain = web3.utils.hexToNumberString(res)
          if (web3.utils.hexToNumberString(res) == 66 || web3.utils.hexToNumberString(res) == 65 || web3.utils.hexToNumberString(res) == 1) {
            that.show = true
          } else {
            that.show = false
            that.$toast({
              duration: 0,
              type: "fail",
              message: this.$t('请切换OKT链')
            });
            that.getChain()
          }
        });
      }
    },
    mounted() {
      let self = this;
      this.$nextTick(() => {
        self.$web3Fun.init().then(() => {
          let web3 = self.$web3Fun.getWeb3();
          self.$web3Fun.getContract(web3, self.$abi);
        });
      })
    }
  };
</script>

<style lang="less">
  .van-dialog__header,
  .van-dialog__message {
    color: #000;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.15s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  // 修改van-toast--loading颜色 用就打开注释改 否则忽略
  // .van-toast--loading {
  //   box-shadow: 0px 1vw 3vw rgba(0, 0, 0, 0.16);
  //   background-color: #fff !important;
  //   .van-toast__loading {
  //     color: #333;
  //   }
  //   .van-toast__text {
  //     color: #333;
  //   }
  // }
  .van-dialog {
    background: linear-gradient(90deg, #320273 0%, #070226 100%);
    border-radius: 8px;
    border: 1px solid #7F4AF4;
    box-shadow: 0px 3px 10px 1px rgba(255, 236, 209, 0.13);
    .van-dialog__header {
      color: #fff;
    }
    .van-dialog__message--has-title {
      color: #fff;
    }
    .van-dialog__cancel {
      .van-button__text {
        color: #fff;
      }
    }
  }
</style>
<style lang="less" scoped>
  .box {
    width: 100%;
    font-size: 15px;
    text-align: center;
  }
</style>